import React, { useEffect, useMemo, useRef, useState } from "react";
import Button from "../ui/Button";
import Input from "../ui/Input";
import { useTable } from "./Table";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import { createPortal } from "react-dom";

const Cell = ({ row, col }) => {
  const [isEditing, setIsEditing] = useState(false);

  const editBtn = useRef();

  const { onEdit } = useTable();

  const renderContent = () => {
    if (col.key === "voted") {
      return Number(row[col.key]) === 1 ? "Voted" : "Not Voted";
    }

    if (
      col.key === "phone_number" &&
      row[col.key] &&
      row[col.key] !== "missing"
    ) {
      const str = row[col.key].toString();

      return (
        <a href={`tel:+1${str}`} className="underline">
          {str.slice(0, 3) + "-" + str.slice(3, 6) + "-" + str.slice(6)}
        </a>
      );
    }

    if (col.key === "timestamp_epoch" && row[col.key]) {
      const date = new Date(+row[col.key]); // Create a Date object from the timestamp

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
      const day = String(date.getDate()).padStart(2, "0");

      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    if (col.isJson) {
      return <JsonCell row={row} col={col} />;
    }

    if (row[col.key]?.toString) return row[col.key].toString();

    return row[col.key];
  };

  return (
    <td
      className={`text-start text-sm lg:text-base first:pl-4 whitespace-nowrap relative group ${
        col.className ? col.className : ""
      } ${isEditing ? "bg-[#f3f3f5] z-10" : "hover:bg-[#f3f3f5]"}`}
    >
      {renderContent()}

      {col.canEdit && (
        <>
          <div
            ref={editBtn}
            className="h-full absolute right-2 flex items-center top-0"
          >
            <button
              onClick={() => setIsEditing((prev) => !prev)}
              className={`bg-white p-1 rounded-md ${
                isEditing ? "" : "hidden group-hover:block"
              }`}
            >
              <img src="/edit.svg" alt="" className="size-[18px]" />
            </button>
          </div>

          {isEditing && (
            <EditModal
              defaultValue={row[col.key]}
              close={() => setIsEditing(false)}
              onEdit={(value) => onEdit(row, col, value)}
              offsetContainer={editBtn.current}
            />
          )}
        </>
      )}
    </td>
  );
};

export default Cell;

const EditModal = ({ defaultValue, close, onEdit, offsetContainer }) => {
  const [editValue, setEditValue] = useState(defaultValue);

  const modal = useRef();

  const { status } = useTable();

  const handleSubmit = (e) => {
    e.preventDefault();
    onEdit(editValue);
  };

  useEffect(() => {
    const { top, left } = offsetContainer.getBoundingClientRect();

    const modalWidth = modal.current.getBoundingClientRect().width;

    if (left < modalWidth) modal.current.style.translate = "30px 0px";
    else modal.current.style.translate = "calc(-100% - 4px) 0px";

    modal.current.style.top = `${window.scrollY + top}px`;
    modal.current.style.left = `${left}px`;
  }, []);

  useUpdateEffect(() => {
    if (status.edit === "idle") close();
  }, [status.edit]);

  return createPortal(
    <>
      <div onClick={close} className="fixed inset-0 z-50"></div>

      <div
        ref={modal}
        className="absolute bg-white border px-3 pb-3 flex flex-col items-end z-50"
      >
        <div className="h-[52px] flex items-center w-full justify-between">
          <h6 className="text-xl font-semibold">Edit Cell</h6>

          <button
            onClick={close}
            className="border size-8 flex justify-center items-center rounded-md"
          >
            <img src="/cross.svg" alt="close" className="size-4" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="flex flex-col gap-3">
          <Input
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            placeholder="Enter value here"
            className="!h-11"
          />

          <div className="flex gap-2 self-end">
            <Button
              type="button"
              onClick={close}
              className="bg-transparent !text-cherry"
            >
              Cancel
            </Button>

            <Button
              type="submit"
              state={status.edit}
              disabled={
                editValue === "" ||
                editValue === defaultValue ||
                status.edit === "loading"
              }
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </>,
    document.querySelector("#root")
  );
};

const JsonCell = ({ row, col }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  let value = JSON.stringify(JSON.parse(row[col.key]), null, 2);

  if (!isExpanded && row[col.key] !== "{}") value = "{...}";

  return (
    <div className={`flex gap-1 ${isExpanded ? "py-3.5" : "py-0"}`}>
      {row[col.key] !== "{}" && (
        <div className="h-5 flex items-center">
          <button
            className="size-5"
            onClick={() => setIsExpanded((prev) => !prev)}
          >
            <img
              src="/chevron-down.svg"
              alt=""
              className={`w-full h-full ${isExpanded ? "" : "-rotate-90"}`}
            />
          </button>
        </div>
      )}

      <pre>{value}</pre>
    </div>
  );
};
