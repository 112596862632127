import React, { useEffect, useRef, useState } from "react";
import { useTable } from "./Table";

const DragPreview = () => {
  const [dragCol, setDragCol] = useState(null);
  const [colStyle, setColStyle] = useState({ x: 0, y: 0, width: 0 });

  const { columns, sort, onReorder } = useTable();

  const draggableCol = useRef();

  const dragTimeoutRef = useRef(null);

  const handleMouseDown = (e) => {
    const columnElem = e.currentTarget;

    const key = columnElem.getAttribute("data-col-key");
    const column = columns.find((col) => col.key === key);

    dragTimeoutRef.current = setTimeout(() => {
      setDragCol(column);

      setColStyle({
        x: e.clientX - columnElem.getBoundingClientRect().width / 2,
        y: e.clientY - columnElem.getBoundingClientRect().height / 2,
        width: columnElem.getBoundingClientRect().width,
        height: columnElem.getBoundingClientRect().height,
      });
    }, 200);
  };

  const handleMouseMove = (e) => {
    if (!dragCol) return;

    const newColStyle = {
      ...colStyle,
      x: e.clientX - colStyle.width / 2,
      y: e.clientY - colStyle.height / 2,
    };
    setColStyle(newColStyle);

    const cols = [...document.querySelectorAll(`.sort`)];

    let activeColIndex = null;

    for (let i in cols) {
      if (Math.abs(e.clientX - cols[i]?.getBoundingClientRect()?.x) < 30) {
        activeColIndex = i;
        break;
      }
    }

    if (activeColIndex !== null) {
      const toBeReplacedWith = columns.filter((c) => c.isActive)[
        activeColIndex
      ];

      let oldIndex = null;
      let toBeReplacedWithIndex = null;

      columns.forEach((col, i) => {
        if (col.key === dragCol.key) oldIndex = i;
        if (col.key === toBeReplacedWith.key) toBeReplacedWithIndex = i;
      });

      const newCols = [...columns];

      [newCols[toBeReplacedWithIndex], newCols[oldIndex]] = [
        newCols[oldIndex],
        newCols[toBeReplacedWithIndex],
      ];

      onReorder(newCols);
    }
  };

  const handleMouseUp = () => {
    if (dragTimeoutRef.current) clearTimeout(dragTimeoutRef.current);
    setDragCol(null);
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, [dragCol]);

  useEffect(() => {
    window.addEventListener("mouseup", handleMouseUp);
    return () => window.removeEventListener("mouseup", handleMouseUp);
  }, []);

  useEffect(() => {
    const th = document.querySelectorAll(`.sort`);

    th.forEach((elem) => elem.addEventListener("mousedown", handleMouseDown));
    return th.forEach((elem) =>
      elem.addEventListener("mousedown", handleMouseDown)
    );
  }, []);

  return (
    dragCol !== null && (
      <>
        <div className="z-[51] fixed inset-0 bg-black/30"></div>

        <div
          ref={draggableCol}
          style={{
            translate: `${colStyle.x}px ${colStyle.y}px`,
            width: `${colStyle.width}px`,
            height: `${colStyle.height}px`,
          }}
          className={`text-start font-semibold transition-colors cursor-pointer select-none text-sm lg:text-base absolute flex items-center bg-[white] z-[52] top-[0px] left-0 px-2 ${
            sort.column === dragCol.key ? "text-[#0e7490]" : ""
          }`}
        >
          <div className="flex gap-[5px] sm:gap-2 h-10 items-center rounded-md w-max">
            <span>{dragCol.text}</span>
            <div className="flex justify-center w-[17px] lg:w-5 translate-y-[0px]">
              {sort.column !== dragCol.key && (
                <img src="/flip.svg" alt="flip" className="w-[17px] lg:w-5" />
              )}
              {sort.column === dragCol.key &&
                (sort.order === "asc" ? (
                  <img
                    src="/ascending.svg"
                    alt="..."
                    className="w-[15px] lg:w-[18px]"
                  />
                ) : (
                  <img
                    src="/descending.svg"
                    alt="..."
                    className="w-[15px] lg:w-[18px]"
                  />
                ))}
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default DragPreview;
