export const DEFAULT_COLUMNS = [
  {
    key: "last_name",
    text: "Last Name",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "first_name",
    text: "First Name",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "middle_name",
    text: "Middle Name",
    isActive: false,
    filter: { type: "search", value: "" },
  },
  {
    key: "voter_id",
    text: "Voter ID",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "house_number",
    text: "House Number",
    isActive: false,
    filter: { type: "search", value: "" },
  },
  {
    key: "street_name",
    text: "Street Name",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "zipcode",
    text: "Zip Code",
    isActive: false,
    filter: { type: "search", value: "" },
  },
  {
    key: "age",
    text: "Age",
    isActive: false,
    filter: { type: "search", value: "" },
  },
  {
    key: "city",
    text: "City",
    className: "capitalize",
    isActive: false,
    filter: { type: "search", value: "" },
  },
  {
    key: "precinct",
    text: "Precinct",
    isActive: false,
    filter: { type: "search", value: "" },
  },
];

export const DEFAULT_SORT = { column: null, order: null };
export const DEFAULT_PER_PAGE = 100;

const memory = JSON.parse(localStorage.getItem("voter-id-lookup-memory"));

export default {
  filteredList: [],
  totalPages: 0,
  page: 1,
  perPage: memory?.perPage || DEFAULT_PER_PAGE,
  sort: memory?.sort || DEFAULT_SORT,
  columns: memory?.columns || DEFAULT_COLUMNS,
  status: {
    fetchData: "idle",
    addVoter: "idle",
  },
};
