import React, { useState } from "react";
import Check from "../svg/Check";
import Cross from "../svg/Cross";

const Input = ({
  variant = "",
  className = "",
  message,
  isValid,
  ...props
}) => {
  if (variant === "password")
    return (
      <PasswordInput
        className={className}
        message={message}
        isValid={isValid}
        {...props}
      />
    );

  if (variant === "phone")
    return <PhoneInput className={className} {...props} />;

  return (
    <input
      {...props}
      className={`rounded-md h-[52px] border px-4 focus-visible:outline-cherry focus-visible:outline-2 focus-visible:outline-double disabled:cursor-not-allowed ${className}`}
    />
  );
};

export default Input;

const PhoneInput = ({ className, onChange = () => {}, ...props }) => {
  const handleChange = (e) => {
    if (e.target.value.split("-").join("").length > 10) return;

    e.target.value = e.target.value
      .replace(/\D/g, "") // Remove any non-digit characters
      .replace(/(\d{3})(\d{1,3})?(\d{1,4})?/, function (_, p1, p2, p3) {
        let formatted = p1;
        if (p2) formatted += "-" + p2;
        if (p3) formatted += "-" + p3;
        return formatted;
      });

    onChange(e);
  };

  return (
    <input
      {...props}
      type="tel"
      onChange={handleChange}
      placeholder="000-000-0000"
      maxLength="12"
      className={`rounded-md h-[52px] border px-4 focus-visible:outline-cherry focus-visible:outline-2 focus-visible:outline-double w-full pr-12 disabled:cursor-not-allowed ${className}`}
    />
  );
};

const PasswordInput = ({ className, message, isValid, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="w-full relative">
      <input
        {...props}
        type={showPassword ? "text" : "password"}
        className={`rounded-md h-[52px] border px-4 focus-visible:outline-cherry focus-visible:outline-2 focus-visible:outline-double w-full disabled:cursor-not-allowed ${
          message === undefined ? "pr-12" : "pr-[76px]"
        } ${className}`}
      />

      <div className="absolute right-3 top-1/2 -translate-y-1/2 flex items-center gap-2 h-full">
        {props.value !== "" && message !== undefined && (
          <div className="relative h-full px-1 flex justify-center items-center cursor-pointer group">
            {message && (
              <div className="bg-black text-white text-sm absolute max-w-[300px] w-max font-semibold leading-[1.3] px-3 py-1.5 z-10 bottom-full translate-y-2.5 rounded-[3px] pointer-events-none opacity-0 group-hover:pointer-events-auto group-hover:opacity-100 transition-opacity">
                <p>{message}</p>
              </div>
            )}
            {isValid ? (
              <Check className="text-[green] size-[22px]" />
            ) : (
              <Cross className="text-cherry size-[18px]" />
            )}
          </div>
        )}

        <button
          type="button"
          className=""
          onClick={() => setShowPassword((prev) => !prev)}
        >
          {showPassword ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
};
