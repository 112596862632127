import React from "react";

const Hero = () => {
  return (
    <div id="hero" className="flex justify-center">
      <div className="w-[90%] max-w-[1200px] flex flex-col items-center gap-[40px] sm:gap-[60px] mt-[120px] sm:mt-[130px]">
        <img
          width={489}
          height={282}
          src="/hero-img.png"
          alt="fired up to vote"
          className="max-w-[482px] min-w-[250px] w-[60%]"
        />

        <p className="text-xl sm:text-2xl font-bold leading-[1.65] text-black text-center">
          FIRED UP TO VOTE is a peer-to-peer get-out-the-vote platform that
          empowers concerned, engaged citizens in Harris County to use their
          personal networks and spheres of influence to inform and mobilize
          their connections to VOTE and impact election outcomes.
        </p>

        <img
          src="/crowd-holding-vote-signs.png"
          alt="crowd holding vote signs"
          className="w-full"
          width={1081}
          height={347}
        />
      </div>
    </div>
  );
};

export default Hero;