import React, { useState } from "react";
import { useTable } from "./Table";
import Ascending from "../svg/Ascending";
import Descending from "../svg/Descending";
import useDebounce from "../../hooks/useDebounce";
import InformationCircle from "../svg/InformationCircle";

const TableHead = () => {
  const { columns, sort, status, onSortChange, onToggleSelectRow } = useTable();

  const [instantSort, setInstantSort] = useState(sort);

  const updateSort = (column) => {
    if (status.fetchData === "loading") return;

    let newSort = {};

    if (instantSort.column !== column)
      newSort = { column: column, order: "desc" };
    else if (instantSort.order === "asc")
      newSort = { ...instantSort, order: "desc" };
    else newSort = { ...instantSort, order: "asc" };

    setInstantSort(newSort);
  };

  useDebounce(() => onSortChange(instantSort), [instantSort], 500, true);

  return (
    <thead className="w-full relative">
      <tr className="border-b h-[52px] rounded-t-lg">
        {onToggleSelectRow && <th></th>}

        {columns
          .filter((col) => col.isDisabled !== true)
          .filter((col) => col.isActive)
          .map((col) => (
            <th
              key={col.key}
              data-col-key={col.key}
              onClick={() => updateSort(col.key)}
              className={`text-start font-semibold transition-all select-none text-sm lg:text-base first:pl-4 sort ${
                status.fetchData === "loading"
                  ? "bg-[#e5e7eb] opacity-60 hover:bg-[#e5e7eb] cursor-default"
                  : instantSort.column === col.key
                  ? "hover:bg-[#ecfeff] text-[#0e7490] cursor-pointer"
                  : "hover:bg-[#F9F9FA] cursor-pointer"
              }`}
            >
              <div className="flex justify-between items-center gap-4">
                <div className="flex gap-[5px] sm:gap-2 h-10 items-center rounded-md w-max">
                  <span>{col.text}</span>
                  <div className="flex justify-center w-[17px] lg:w-5">
                    {instantSort.column !== col.key && (
                      <img
                        src="/flip.svg"
                        alt="flip"
                        className="size-[17px] lg:size-5"
                      />
                    )}

                    {instantSort.column === col.key &&
                      (instantSort.order === "asc" ? (
                        <Ascending className="size-[15px] lg:size-[18px]" />
                      ) : (
                        <Descending className="size-[15px] lg:size-[18px]" />
                      ))}
                  </div>
                </div>

                {col.tooltip && (
                  <div className="group">
                    <InformationCircle className="size-6 cursor-pointer" />

                    <div className="absolute opacity-0 -translate-x-1/2 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto group-hover:translate-y-1 transition-all duration-200 before:absolute before:bottom-full before:w-full before:left-0 before:h-1 bg-black/40 z-50 text-white px-2.5 py-2 rounded-md leading-[1.4] backdrop-blur-md w-full max-w-[300px]">
                      {col.tooltip}
                    </div>
                  </div>
                )}
              </div>
            </th>
          ))}
      </tr>
    </thead>
  );
};

export default TableHead;
