import { createSlice } from "@reduxjs/toolkit";
import initialState, { DEFAULT_COLUMNS, DEFAULT_SORT } from "./initialState";
import {
  deleteAccount,
  deleteData,
  edit,
  exportData,
  fetchFilteredList,
  updateStatus,
  upload,
} from "./adminApi";
import downloadAsCsv from "../../utils/downloadAsCsv";

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      const [key, newFilterValue] = action.payload;

      state.columns = state.columns.map((col) => {
        if (col.key !== key) return col;
        return { ...col, filter: { ...col.filter, value: newFilterValue } };
      });
    },

    moveToPage: (state, action) => {
      state.page = action.payload;
    },

    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },

    toggleColumn: (state, action) => {
      const key = action.payload;

      state.columns = state.columns.map((col) => {
        if (col.key === key)
          return {
            ...col,
            isActive: !col.isActive,
            filter: { ...col.filter, value: "" }, // resets filter value
          };
        return col;
      });

      if (state.sort.column === key) state.sort = { column: null, order: null }; // resets sort if any
    },

    resetFilters: (state) => {
      localStorage.removeItem("admin-memory");

      state.columns = DEFAULT_COLUMNS.map((col, i) => ({
        ...col,
        isActive: state.columns[i].isActive,
      }));
      state.sort = DEFAULT_SORT;
    },

    setSort: (state, action) => {
      state.sort = action.payload;
    },

    updateUploadStatus: (state, action) => {
      const { percent } = action.payload;
      state.status.upload = percent;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchFilteredList.pending, (state) => {
        state.status.fetchData = "loading";
      })
      .addCase(fetchFilteredList.fulfilled, (state, action) => {
        state.status.fetchData = "idle";
        state.filteredList = action.payload.data || [];
        state.totalPages =
          action.payload.metadata.total_pages || state.totalPages;
        state.totalUsers =
          action.payload.metadata.total_users || state.totalUsers;
      })
      .addCase(fetchFilteredList.rejected, (state, action) => {
        if (!action.payload?.aborted) state.status.fetchData = "idle";
      })

      .addCase(updateStatus.pending, (state) => {
        state.status.editStatus = "loading";
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
        state.status.editStatus = "idle";
        state.filteredList = action.payload.data.users || [];
        state.totalPages =
          action.payload.metadata.total_pages || state.totalPages;
        state.totalUsers =
          action.payload.metadata.total_users || state.totalUsers;
      })
      .addCase(updateStatus.rejected, (state) => {
        state.status.editStatus = "idle";
      })

      .addCase(deleteAccount.pending, (state) => {
        state.status.deleteAccount = "loading";
      })
      .addCase(deleteAccount.fulfilled, (state, action) => {
        state.status.deleteAccount = "idle";
        state.filteredList = action.payload.data.users || [];
        state.totalPages =
          action.payload.metadata.total_pages || state.totalPages;
        state.totalUsers =
          action.payload.metadata.total_users || state.totalUsers;
      })
      .addCase(deleteAccount.rejected, (state) => {
        state.status.deleteAccount = "idle";
      })

      .addCase(deleteData.pending, (state) => {
        state.status.deleteData = "loading";
      })
      .addCase(deleteData.fulfilled, (state) => {
        state.status.deleteData = "idle";
      })
      .addCase(deleteData.rejected, (state) => {
        state.status.deleteData = "idle";
      })

      .addCase(edit.pending, (state) => {
        state.status.edit = "loading";
      })
      .addCase(edit.fulfilled, (state, action) => {
        state.status.edit = "idle";
        if (action.payload.data) {
          state.filteredList = action.payload.data;
        }
        if (action.payload.metadata) {
          state.totalPages = action.payload.metadata.total_pages;
        }
      })
      .addCase(edit.rejected, (state) => {
        state.status.edit = "idle";
      })

      .addCase(exportData.pending, (state) => {
        state.status.export = "loading";
      })
      .addCase(exportData.fulfilled, (state, action) => {
        state.status.export = "idle";
        console.log(action.payload.data);
        if (action.payload.data) downloadAsCsv(action.payload.data);
      })
      .addCase(exportData.rejected, (state) => {
        state.status.export = "idle";
      })

      .addCase(upload.pending, (state) => {
        state.status.upload = 0;
      })
      .addCase(upload.fulfilled, (state) => {
        state.status.upload = "idle";
      })
      .addCase(upload.rejected, (state) => {
        state.status.upload = "idle";
      });
  },
});

export const {
  updateFilters,
  moveToPage,
  setPerPage,
  toggleColumn,
  resetFilters,
  setSort,
  updateUploadStatus,
} = adminSlice.actions;
export default adminSlice.reducer;
