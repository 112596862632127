import React from "react";
import { useSelector } from "react-redux";

const icons = [
  "/user-list.svg",
  "/package-open.svg",
  "/speedometer.svg",
  "/user-question.svg",
];

const Main = () => {
  const { data } = useSelector((state) => state.dashboard);

  return (
    <div className="flex justify-center w-full font-fira mt-[100px] sm:mt-[110px] xl:mt-[130px] items-center mb-10 xs:mb-12 sm:mb-14 lg:mb-20">
      <div className="flex flex-col gap-6 sm:gap-12 w-[90%] max-w-[1400px] items-center">
        <div className="text-center space-y-1 sm:space-y-2">
          <h2 className="text-[28px] sm:text-[32px] text-[#323232] font-medium leading-[1.25]">
            Your Voting Progress Overview
          </h2>

          <p className="text-[#727272] sm:text-lg">
            Track Your Added Voters and Their Participation
          </p>
        </div>

        <div className="flex flex-col gap-4 sm:gap-6 w-full rounded-xl max-w-[500px]">
          {Object.keys(data).map((key, i) => (
            <div key={i} className="flex justify-between w-full items-center">
              <div className="flex items-center gap-2.5">
                <img src={icons[i]} alt="user list" />
                <span className="text-lg sm:text-xl text-cherry">{key}</span>
              </div>

              <span className="text-[22px] sm:text-2xl text-cherry font-bold">
                {data[key]}
              </span>
            </div>
          ))}
        </div>

        {/* <div className="flex gap-y-8 gap-x-8 xl:gap-x-10 justify-center flex-wrap">
          {Object.keys(data).map((key, index) => {
            const isPercentageStat = data[key].includes("%");

            return !isPercentageStat ? (
              <div
                key={index}
                className="w-[320px] flex flex-col items-center justify-center text-[27px] xl:text-3xl p-5 text-center aspect-square border-[20px] lg:border-[24px] border-cherry rounded-full gap-2 lg:gap-3"
              >
                <span className="leading-[1]">{key}</span>
                <span className="leading-[1] font-semibold">{data[key]}</span>
              </div>
            ) : (
              <div
                key={index}
                className="w-[320px] flex flex-col items-center justify-center text-[27px] xl:text-3xl p-5 text-center aspect-square border-[20px] lg:border-[24px] border-transparent rounded-full gap-2 lg:gap-3 relative"
              >
                <div
                  style={{
                    background: !data
                      ? "conic-gradient(#C82446 0% 100%)"
                      : `conic-gradient(#eaeaea 0% ${
                          100 - data[key].slice(0, -1)
                        }%, #C82446 ${100 - data[key].slice(0, -1)}% 100%)`,
                  }}
                  className="absolute -inset-[24px] rounded-full -z-10 transition-all"
                ></div>
                <div className="absolute inset-0 bg-white rounded-full -z-10"></div>

                <span className="leading-[1]">{key}</span>
                <span className="leading-[1] font-semibold">{data[key]}</span>
              </div>
            );
          })}
        </div> */}
      </div>
    </div>
  );
};

export default Main;
