import React from "react";
import { Steps } from "intro.js-react";

const MyListSteps = (props) => {
  return (
    <Steps
      steps={[
        {
          element: "#my-list #add-voter-btn",
          title: "Add Your Contacts",
          intro:
            "Use this button to upload your prepared list of contacts into the system.",
        },
        {
          element: "#my-list #toggle-columns",
          title: "Customize Your View",
          intro:
            "Click here to choose which information columns you want to see in your list.",
        },
        {
          element: "#my-list .sort",
          title: "Organize Your List",
          intro:
            "Click on any column header to sort your list based on that information. Click again to reverse the order.",
        },
        {
          element: "#my-list #select-all-rows",
          title: "Select Visible Contacts",
          intro:
            "This checkbox selects all contacts currently visible on your screen (up to 10 at a time). It's useful for performing actions on a group of contacts you can see.",
        },
        {
          element: "#my-list .filter",
          title: "Find Specific Contacts",
          intro:
            "Type here to search for specific information within your list. This helps you quickly find particular contacts or groups.",
        },
        {
          element: "#my-list #action",
          title: "Manage Contacts",
          intro:
            "Click on the three dots (...) to open a menu with actions for the selected contact(s). You can move them to a different list or remove them from your current list.",
        },
      ]}
      initialStep={0}
      options={{ doneLabel: "Done" }}
      {...props}
    />
  );
};

export default MyListSteps;
