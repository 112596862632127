import React from "react";
import { useTable } from "./Table";
import {
  Dropdown,
  DropdownMenu,
  DropdownOption,
  DropdownTrigger,
  SubDropdownMenu,
} from "../../components/ui/Dropdown";
import Cell from "./Cell";

const TableRow = ({ row, index, actions }) => {
  const { columns, onToggleSelectRow, selectedRows } = useTable();

  return (
    <tr
      className={`border-b h-[52px] relative transition-colors row-${index} ${
        selectedRows && selectedRows.includes(row.id) ? "bg-[#f9f9f9]" : ""
      }`}
    >
      {onToggleSelectRow && selectedRows && (
        <td className="min-w-[40px]">
          <div className="flex justify-center text-sm lg:text-base select-row">
            <input
              type="checkbox"
              className="checkbox checkbox-black"
              checked={selectedRows.includes(row.id)}
              onChange={() => {
                onToggleSelectRow(row);
              }}
            />
          </div>
        </td>
      )}

      {columns
        .filter((col) => col.isDisabled !== true)
        .filter((col) => col.isActive)
        .map((col) => (
          <Cell
            key={col.key}
            row={row}
            col={col}
            rowClass={`.row-${index}`}
            z={-index}
          />
        ))}

      {actions?.isDropdown === false && (
        <td className="sticky right-0 bg-white border-l">
          <button
            id="action"
            disabled={actions.state === "loading" || row.isDisabled}
            onClick={() => {
              actions.onClick(row);
            }}
            className={`h-6 text-sm text-[15px] disabled:opacity-70 font-bold w-full ${
              actions?.className(row.isDisabled) || ""
            }`}
          >
            {actions.label}
          </button>
        </td>
      )}

      {actions?.isDropdown && (
        <td
          style={{ zIndex: 100 - index }}
          className="sticky right-0 bg-white w-14"
        >
          <Dropdown
            className="w-max mx-auto"
            parent={`.row-${index}`}
            triggers={actions.triggers}
            defaultZIndex={-index}
          >
            <DropdownTrigger
              id="action"
              disabled={actions.state === "loading" || row.isDisabled}
              className={`h-6 text-sm text-[15px] disabled:opacity-70 font-bold w-full ${
                (actions.className && actions?.className(row.isDisabled)) || ""
              }`}
            >
              {actions.label || (
                <img
                  src="/dots.svg"
                  alt="menu"
                  className="min-w-[24px] size-6"
                />
              )}
            </DropdownTrigger>

            <DropdownMenu state={actions.state}>
              {actions.dropdownTitle !== null && (
                <DropdownOption type="heading">
                  {actions.dropdownTitle || "Actions"}
                </DropdownOption>
              )}

              {actions.options?.map(
                (opt1, i) =>
                  (!opt1.condition || opt1.condition(row)) && (
                    <DropdownOption
                      key={i}
                      type={opt1.type}
                      onClick={() => {
                        if (opt1.onClick) opt1.onClick(row);
                      }}
                    >
                      {opt1.label}

                      {opt1.menu && (
                        <SubDropdownMenu state={opt1.menu.state}>
                          {opt1.menu.options.map((opt2, i) => (
                            <DropdownOption
                              key={i}
                              type={opt2.type}
                              onClick={() => {
                                if (opt2.onClick) opt2.onClick(row);
                              }}
                            >
                              {opt2.label}
                            </DropdownOption>
                          ))}
                        </SubDropdownMenu>
                      )}
                    </DropdownOption>
                  )
              )}
            </DropdownMenu>
          </Dropdown>
        </td>
      )}
    </tr>
  );
};

export default TableRow;
