import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="footer" className="flex justify-center mt-auto mb-14 -z-10">
      <div className="w-[90%] max-w-[1400px]">
        <div className="pb-10 xs:pb-12 sm:pb-14 lg:pb-20 flex gap-8 md:gap-16 lg:gap-24 relative flex-col md:flex-row">
          <div className="space-y-5 lg:space-y-7">
            <img
              src="/hero-img.png"
              alt="fired up to vote"
              width={250}
              height={144}
              className="w-[220px] lg:w-[250px]"
            />

            <p className="font-fira text-lg lg:text-[21px] font-medium max-w-[300px]">
              Join us today to empower Houston voters and drive positive change
              in our community.
            </p>
          </div>

          <div className="md:pt-12 flex flex-col gap-6">
            <div className="space-y-2">
              <h6 className="text-cherry font-fira font-extrabold text-[26px]">
                CONTACT
              </h6>

              <ul className="font-fira font-medium text-lg lg:text-[21px]">
                <li>Email: support@fireduptovote.com</li>
                {/* <li>Phone: 000-000-0000</li> */}
              </ul>
            </div>

            <Link
              to="/privacy-policy"
              className="font-fira text-xl hover:underline"
            >
              Privacy Policy
            </Link>
          </div>

          <div className="hidden md:flex absolute inset-0 pt-32 pb-4 justify-end -z-10">
            <img
              src="/queue-for-voting.png"
              alt="queue for voting"
              className="h-full"
            />
          </div>
        </div>

        <div className="w-full h-[3px] border border-black"></div>

        <div className="text-center font-fira text-black font-medium text-[13px] py-6">
          ©FIRED UP TO VOTE. DESIGNED BY PWL STUDIO
        </div>
      </div>
    </footer>
  );
};

export default Footer;
