import React, { useState } from "react";
import Input from "../../components/ui/Input";
import {
  Dropdown,
  DropdownMenu,
  DropdownOption,
  DropdownTrigger,
} from "../../components/ui/Dropdown";
import Button from "../../components/ui/Button";
import { useDispatch, useSelector } from "react-redux";
import { exportData } from "../../features/adminSlice/adminApi";
import { useNavigate } from "react-router-dom";

const statusOptions = ["complete", "incomplete", "both"];

const ExportModal = ({ close }) => {
  const [formData, setFormData] = useState({
    start_date: "",
    end_date: "",
    status: "",
  });

  const { status } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const formatDate = (rawDate) => {
    const [year, month, date] = rawDate.split("-");
    return `${month}/${date}/${year}`;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const params = {
      ...formData,
      start_date: formatDate(formData.start_date),
      end_date: formatDate(formData.end_date),
    };

    dispatch(exportData({ navigate, ...params }));
  };

  return (
    <div className="fixed z-[61] inset-0 isolate flex justify-center items-center">
      <div
        className="absolute inset-0 bg-black bg-opacity-30 cursor-pointer -z-10"
        onClick={close}
      ></div>

      <div className="w-[90%] max-w-[500px] bg-white flex flex-col rounded-xl relative p-5 gap-5">
        <button
          onClick={close}
          className="bg-white border-black border size-8 min-w-8 rounded-lg flex justify-center items-center absolute right-5 top-5"
        >
          <img src="/cross.svg" className="size-4" />
        </button>

        <h6 className="text-xl xs:text-2xl text-[#323232] font-semibold font-mont !leading-[1.3]">
          Export Users
        </h6>

        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <label htmlFor="start_date" className="font-medium">
              Start Date
            </label>
            <Input
              type="date"
              name="start_date"
              id="start_date"
              className="w-full"
              max={formData.end_date}
              value={formData.start_date}
              onChange={handleChange}
            />
          </div>

          <div className="flex flex-col gap-1">
            <label htmlFor="end_date" className="font-medium">
              End Date
            </label>
            <Input
              type="date"
              name="end_date"
              id="end_date"
              className="w-full"
              min={formData.start_date}
              value={formData.end_date}
              onChange={handleChange}
            />
          </div>

          <Dropdown triggers={[formData.status]}>
            <DropdownTrigger className="flex items-center font-semibold gap-2 justify-between border !h-11 px-2 md:px-3 hover:bg-[#F9F9FA] transition-colors rounded-md text-sm lg:text-[15px] relative capitalize">
              {formData.status || "Status"}
              <img src={"/chevron-down.svg"} className="size-6" />
            </DropdownTrigger>

            <DropdownMenu>
              {statusOptions.map((status, i) => (
                <DropdownOption
                  key={i}
                  onClick={() => setFormData((prev) => ({ ...prev, status }))}
                  className="capitalize"
                >
                  {status}
                </DropdownOption>
              ))}
            </DropdownMenu>
          </Dropdown>

          <Button
            state={status.export}
            disabled={
              status.export === "loading" ||
              Object.values(formData).some((v) => !v)
            }
            type="submit"
            className="!h-11"
          >
            Export
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ExportModal;
