import React from "react";

const resources = [
  {
    text: "Voter information for upcoming election",
    link: "https://www.harrisvotes.com/Voter/View-information-for-upcoming-Elections",
  },
  {
    text: "What's on my ballot?",
    link: "https://www.harrisvotes.com/Voter/Whats-on-my-Ballot",
  },
  { text: "Where to vote?", link: "https://www.harrisvotes.com/Vote-Centers" },
  {
    text: "Search for your Voter ID (VUID)",
    link: "https://www.hctax.net/Voter/Search.cshtml",
  },
  {
    text: "Mail ballot application",
    link: "https://files.harrisvotes.com/harrisvotes/prd/docs/VotingInfo/2023%20HCCO%20Mail-in-Ballot_EN.pdf",
  },
  { text: "Donate", link: "https://givebutter.com/2PSZC5" },
];

const Resources = () => {
  return (
    <div
      id="resources"
      className="bg-peach font-fira flex justify-center text-center text-cherry py-10 sm:py-[50px] md:py-[70px]"
    >
      <div className="justify-center items-center flex flex-col gap-2 sm:gap-4 lg:gap-6 tracking-[0.6px] w-[90%] max-w-[1000px]">
        <h2 className="text-[28px] xs:text-[32px] sm:text-[36px] md:text-[42px] font-black">
          RESOURCES
        </h2>

        <p className="text-lg sm:text-xl font-black leading-[1.65] max-w-[800px]">
          Voters are more likely to vote when they are informed about the issues
          and when someone they know encourages them to vote. Fired Up To Vote
          provides information relevant to state, county, and local elections
          and is a platform for people to engage with voters they know
          personally to encourage them to VOTE!
        </p>

        <div className="grid xs:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-4 mt-5 xs:mt-8 lg:mt-12">
          {resources.map((resource, index) => (
            <a
              key={index}
              target="_blank"
              href={resource.link}
              className="text-xl md:text-2xl lg:text-[26px] font-extrabold uppercase tracking-[0.7px] leading-[1.1] border-[3px] border-cherry flex justify-center items-center rounded-xl h-[130px] xs:h-[150px] md:h-[160px] lg:h-[200px] px-7"
            >
              {resource.text}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Resources;
