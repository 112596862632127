import React from "react";

const Quote = () => {
  return (
    <div className="mb-4 flex justify-center text-center py-20">
      <p className="relative text-[#9FA3A4] text-[18px] sm:text-[24px] md:text-[30px] lg:text-[38px] max-w-[30em] text-center font-fira font-extrabold sm:font-black leading-[1.15] tracking-[0.5px]">
        "THE PRICE GOOD MEN PAY FOR INDIFFERENCE TO PUBLIC <br />AFFAIRS IS TO BE RULED BY EVIL MEN."
        <span className="text-black text-[12px] sm:text-[14px] md:text-[18px] lg:text-[22px] font-medium absolute bottom-0 right-[5%] translate-y-[95%] sm:translate-y-[75%]">
          -PLATO
        </span>
      </p>
    </div>
  );
};

export default Quote;