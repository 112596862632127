import { createAsyncThunk } from "@reduxjs/toolkit";
import post from "../../utils/post";

export const fetchData = createAsyncThunk(
  "dashboard/fetchData",
  async ({ navigate }) => {
    const response = await post(
      process.env.REACT_APP_DASHBOARD_STATS_API_URL,
      null,
      navigate,
      null,
      null,
      {},
      false
    );

    return response.data;
  }
);
