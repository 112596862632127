import React, { useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import { useTable } from "./Table";

const SearchFilter = ({
  value,
  colKey,
  shouldDebounce,
  className = "",
  id = "",
}) => {
  const [inputValue, setInputValue] = useState(value);

  const { onFiltersChange } = useTable();

  useDebounce(
    () => {
      onFiltersChange(colKey, inputValue);
    },
    [inputValue],
    shouldDebounce ? 500 : 0,
    true
  );

  return (
    <td id={`filter-${colKey}`} className={`h-[52px] py-1.5 first:pl-4 filter`}>
      <input
        placeholder="Search"
        value={inputValue}
        name={colKey}
        onChange={(e) => setInputValue(e.target.value)}
        className="rounded-md h-full w-full border px-2 text-[13px] lg:text-[15px] focus-visible:outline-cherry focus-visible:outline-2 focus-visible:outline-double"
      />
    </td>
  );
};

export default SearchFilter;
