import React, { useState } from "react";
import Header2 from "../../layout/Header2";
import Footer from "../../layout/Footer";

const Resources = () => {
  const [activeResource, setActiveResource] = useState("pdf");

  return (
    <>
      <Header2 />

      <div className="w-full flex justify-center mt-[120px] font-mont mb-10 xs:mb-12 sm:mb-14 lg:mb-20">
        <div className="w-[90%] max-w-[1400px] flex gap-6 relative flex-col items-center">
          <div className="flex gap-4">
            <button
              onClick={() => setActiveResource("pdf")}
              className={`font-semibold border border-black w-[120px] py-2 rounded-md ${
                activeResource === "pdf" ? "bg-black text-white" : ""
              }`}
            >
              PDF
            </button>
            <button
              onClick={() => setActiveResource("video")}
              className={`font-semibold border border-black w-[120px] py-2 rounded-md ${
                activeResource === "video" ? "bg-black text-white" : ""
              }`}
            >
              Video
            </button>
          </div>

          {activeResource === "pdf" ? (
            <iframe
              src="https://drive.google.com/file/d/1b2VA99zbQzYyFaoO7TjR_b7W5uF2kx4n/preview"
              allow="autoplay"
              className="w-full h-[80vh]"
            ></iframe>
          ) : (
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/AlKud9PqJjQ?si=nTVgM01qMfAq9xJP"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
              className="w-full h-[80vh]"
            ></iframe>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Resources;
