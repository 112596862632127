import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLoginForm } from "../../App";
import Button from "../../components/ui/Button";
import Input from "../../components/ui/Input";
import useAxios from "../../hooks/useAxios";
import { useDispatch } from "react-redux";
import { updateListNames } from "../../features/myListSlice/myListSlice";
import { fetchData } from "../../features/myListSlice/myListApi";
import { toast } from "react-toastify";

const Main = ({ setUserInfo }) => {
  const { loginForm: formData, setLoginForm: setFormData } = useLoginForm();

  const [buttonState, setButtonState] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { post } = useAxios();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (buttonState === "loading") return;

    const email = formData.email.toLowerCase().trim();

    setButtonState("loading");

    try {
      await post(
        process.env.REACT_APP_USER_LOGIN_API_URL,
        { email, password: formData.password, purpose: "login" },
        (response) => {
          localStorage.setItem("jwt-token", response.data.data.token);
          localStorage.setItem("user-role", response.data.data.role);
          if (response.data.data.user_data.multi_column)
            localStorage.setItem(
              "multi-column",
              response.data.data.user_data.multi_column
            );
          localStorage.setItem("email", email);

          if (response.data.data.user_data.list_names) {
            localStorage.setItem(
              "listNames",
              JSON.stringify(response.data.data.user_data.list_names)
            );
            dispatch(updateListNames(response.data.data.user_data.list_names));
          }

          setUserInfo(response.data.data.user_data);
          dispatch(fetchData({ navigate }));
          navigate("/my-list");
        },
        (error) => {
          // Ensure error callback is correctly handled
          if (error.response && error.response.status === 401) {
            toast.error("Invalid email or password");
          } else {
            console.error("Login error:", error);
          }
        },
        { withCredentials: true }
      );
    } finally {
      setButtonState("");
    }
  };

  return (
    <div className="w-full flex justify-center items-center flex-1 min-h-[700px] lg:min-h-[575px] font-mont">
      <div className="w-[90%] max-w-[900px] flex justify-between items-center lg:items-start gap-y-8 sm:gap-y-12 flex-col lg:flex-row lg:translate-y-[-3vh]">
        <img
          src="/hero-img.png"
          alt="logo"
          className="w-3/4 lg:w-full max-w-[350px]"
        />

        <div
          className="w-full max-w-[450px] lg:max-w-[400px] rounded-md p-4 flex flex-col items-center gap-3"
          style={{
            boxShadow:
              "-1px 0px 7px -1px #00000010, 0px -1px 7px -1px #00000010, 1px 0px 7px -1px #00000010, 0px 1px 7px -1px #00000010",
          }}
        >
          <h1 className="text-[28px] font-semibold mb-1.5 leading-[1]">
            Login
          </h1>

          <form onSubmit={handleSubmit} className="w-full flex flex-col gap-3">
            <Input
              type="email"
              name="email"
              placeholder="Email Address"
              value={formData.email}
              onChange={handleChange}
            />
            <Input
              variant="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
            />

            <Button
              state={buttonState}
              disabled={
                buttonState === "loading" ||
                !formData.email ||
                !formData.password
              }
              className="h-[52px] text-xl"
              type="submit"
            >
              Log In
            </Button>
          </form>
          <Link
            to="/forgot-password"
            className="hover:underline text-cherry font-medium"
          >
            Forgot Password
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Main;
