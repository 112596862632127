import React from "react";
import Hero from "./Hero";
import About from "./About";
import Statement from "./Statement";
import Support from "./Support";
import Resources from "./Resources";
import GetInvolved from "./GetInvolved";
import Footer from "../../layout/Footer";
import Header1 from "../../layout/Header1";
import Quote from "./Quote";
import HowItWorks from "./HowItWorks";

const Landing = () => {
  return (
    <>
      <div id="top" className="absolute top-0"></div>

      <Header1 />
      <Hero />
      <Statement />
      <HowItWorks />
      <GetInvolved />
      <Support />
      <Resources />
      <Quote />
      <Footer />
    </>
  );
};

export default Landing;
