import React, { useEffect, useRef } from "react";
import Table from "../../components/table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchData } from "../../features/logsSlice/logsApi";
import {
  moveToPage,
  resetFilters,
  setPerPage,
  setSort,
  toggleColumn,
  updateFilters,
} from "../../features/logsSlice/logsSlice";
import useMemory from "../../hooks/useMemory";
import TableButton from "../../components/table/TableButton";

const LogsTable = () => {
  const [_, setSearchParams] = useSearchParams();

  const state = useSelector((state) => state.logs);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  // useMemory("logs-memory", state);

  const firstRenderRef = useRef(true);

  // handles the initial fetch, filtering, sorting of the data
  useEffect(() => {
    const controller = new AbortController();
    const options = { signal: controller.signal };

    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      dispatch(fetchData({ navigate, options }));
    } else {
      setSearchParams({ page: 1 });
      dispatch(fetchData({ navigate, options }));
    }

    return () => {
      controller.abort();
    };
  }, [...state.columns.map((c) => c.filter.value), state.sort, state.perPage]);

  const handleSort = ({ column, order }) => {
    dispatch(setSort({ column, order }));
  };

  const handleChangePage = (toPage) => {
    dispatch(moveToPage(toPage));
    dispatch(fetchData({ navigate }));
  };

  const handleFilters = (key, value) => dispatch(updateFilters([key, value]));

  const handleToggleColumn = (key) => dispatch(toggleColumn(key));

  const handleResetFilters = () => dispatch(resetFilters());

  const handleChangePerPage = (perPage) => dispatch(setPerPage(perPage));

  return (
    <>
      <Table
        state={state}
        onSortChange={handleSort}
        onFiltersChange={handleFilters}
        onToggleColumn={handleToggleColumn}
        onResetFilters={handleResetFilters}
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        emptyPlaceholder={(onResetFilters) => (
          <div className="w-full h-[200px] flex justify-center items-center flex-col gap-4">
            <p className="text-xl font-medium">No records found</p>

            {state.columns.some((c) => c.filter.value) && (
              <TableButton onClick={onResetFilters} icon="/import.svg">
                Reset Filters
              </TableButton>
            )}
          </div>
        )}
      />
    </>
  );
};

export default LogsTable;
