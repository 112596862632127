import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "../../components/ui/Button";
import { STEPS } from "./Main";
import Input from "../../components/ui/Input";
import useAxios from "../../hooks/useAxios";

const NameStep = ({
  formData,
  handleChange,
  setStep,
  setFetchedVoters,
  setPreviousFormData,
}) => {
  const [buttonState, setButtonState] = useState("");

  const { post } = useAxios();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonState("loading");

    if (
      formData.first_name === "" ||
      formData.last_name === "" ||
      buttonState === "loading"
    )
      return;

    setPreviousFormData(formData);

    await post(
      process.env.REACT_APP_EMAIL_SIGNUP_API_URL,
      {
        purpose: "submit_name",
        first_name: formData.first_name.toLowerCase().trim(),
        middle_name: formData.middle_name.toLowerCase().trim(),
        last_name: formData.last_name.toLowerCase().trim(),
      },

      (response) => {
        setFetchedVoters(response.data.data);
        setStep(STEPS.MATCH_USER);
      }
    );

    setButtonState(null);
  };

  return (
    <form onSubmit={handleSubmit} className="w-full flex flex-col gap-[18px]">
      <p className="px-4">Please enter your full legal name in the box below</p>

      <div className="flex flex-col gap-3 px-4">
        <Input
          type="text"
          name="first_name"
          placeholder="First Name"
          value={formData.first_name}
          onChange={handleChange}
        />{" "}
        <Input
          type="text"
          name="middle_name"
          placeholder="Middle Name"
          value={formData.middle_name}
          onChange={handleChange}
        />{" "}
        <Input
          type="text"
          name="last_name"
          placeholder="Last Name"
          value={formData.last_name}
          onChange={handleChange}
        />
      </div>

      <div className="w-full h-[1px] bg-[#e5e7eb]"></div>

      <div className="w-full px-4 flex justify-end gap-2">
        <button
          type="button"
          disabled={buttonState === "loading"}
          onClick={() => setStep(STEPS.CANT_FIND_VOTER_ID)}
          className="flex items-center h-9 rounded-md bg-[#e3e4e9] text-[#45484e] px-[22px] font-semibold disabled:opacity-70 disabled:cursor-not-allowed"
        >
          Can't find Voter ID
        </button>

        <Button
          state={buttonState}
          type="submit"
          disabled={
            formData.first_name === "" ||
            formData.last_name === "" ||
            buttonState === "loading"
          }
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

export default NameStep;
