import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ConfirmDeleteAccount from "./ConfirmDeleteAccount";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteData,
  edit,
  fetchFilteredList,
  updateStatus,
} from "../../features/adminSlice/adminApi";
import {
  moveToPage,
  updateFilters,
  setPerPage,
  toggleColumn,
  resetFilters,
  setSort,
} from "../../features/adminSlice/adminSlice";
import useMemory from "../../hooks/useMemory";
import Table from "../../components/table/Table";
import ExportModal from "./ExportModal";
import ConfirmDeleteData from "../../components/ConfirmDeleteData";
import UploadModal from "./UploadModal";
import TableButton from "../../components/table/TableButton";

const AdminTable = () => {
  const [_, setSearchParams] = useSearchParams();

  const [deleteAccountEmail, setDeleteAccountEmail] = useState(null);
  const [deleteDataEmail, setDeleteDataEmail] = useState(null);
  const [uploadUser, setUploadUser] = useState(null);

  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const state = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  // useMemory("admin-memory", state);

  const firstRenderRef = useRef(true);

  useEffect(() => {
    const controller = new AbortController();
    const options = { signal: controller.signal };

    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      dispatch(fetchFilteredList({ navigate, options }));
    } else {
      setSearchParams({ page: 1 });
      dispatch(fetchFilteredList({ navigate, options }));
    }

    return () => {
      controller.abort();
    };
  }, [...state.columns.map((c) => c.filter.value), state.sort, state.perPage]);

  const handleSort = ({ column, order }) => {
    dispatch(setSort({ column, order }));
  };

  const handleUpdateStatus = (email, newStatus) => {
    dispatch(updateStatus({ navigate, email, newStatus }));
  };

  const handleUpdateMulticolumn = (email, newValue) => {
    dispatch(
      edit({ navigate, email, attribute: "multi_column", value: newValue })
    );
  };

  const handleChangePage = (toPage) => {
    dispatch(moveToPage(toPage));
    dispatch(fetchFilteredList({ navigate }));
  };

  const handleFilters = (key, value) => dispatch(updateFilters([key, value]));

  const handleToggleColumn = (key) => dispatch(toggleColumn(key));

  const handleResetFilters = () => dispatch(resetFilters());

  const handleChangePerPage = (perPage) => dispatch(setPerPage(perPage));

  const handleEdit = (row, col, value) => {
    dispatch(
      edit({
        navigate,
        email: row.email,
        attribute: col.key,
        value,
      })
    );
  };

  return (
    <>
      {deleteAccountEmail && (
        <ConfirmDeleteAccount
          email={deleteAccountEmail}
          close={() => setDeleteAccountEmail(null)}
        />
      )}

      {deleteDataEmail && (
        <ConfirmDeleteData
          state={state.status.deleteData}
          close={() => setDeleteDataEmail(null)}
          onDelete={() =>
            dispatch(deleteData({ navigate, email: deleteDataEmail }))
          }
        />
      )}

      {uploadUser && (
        <UploadModal user={uploadUser} close={() => setUploadUser(null)} />
      )}

      {isExportModalOpen && (
        <ExportModal close={() => setIsExportModalOpen(false)} />
      )}

      <h1 className="text-2xl sm:text-3xl font-medium text-center absolute left-1/2 -translate-x-1/2 whitespace-nowrap -top-[1.5em] min-[1320px]:top-0">
        Total Users: {state.totalUsers ?? "Loading"}
      </h1>

      <Table
        state={{ ...state, data: state.filteredList }}
        onSortChange={handleSort}
        onFiltersChange={handleFilters}
        onToggleColumn={handleToggleColumn}
        onResetFilters={handleResetFilters}
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        onEdit={handleEdit}
        emptyPlaceholder={(onResetFilters) => (
          <div className="w-full h-[200px] flex justify-center items-center flex-col gap-4">
            <p className="text-xl font-medium">No records found</p>

            {state.columns.some((c) => c.filter.value) && (
              <TableButton onClick={onResetFilters} icon="/import.svg">
                Reset Filters
              </TableButton>
            )}
          </div>
        )}
        tableActions={[
          { label: "Export Users", onClick: () => setIsExportModalOpen(true) },
        ]}
        rowActions={{
          isDropdown: true,
          triggers: [
            state.status.editStatus,
            state.status.deleteAccount,
            state.status.deleteData,
          ],
          state: state.status.deleteAccount,
          options: [
            {
              label: "Edit Status",
              menu: {
                state: state.status.editStatus,
                options: [
                  {
                    label: "Approve",
                    type: "success",
                    onClick: (row) => handleUpdateStatus(row.email, "active"),
                  },
                  {
                    label: "Deny",
                    type: "danger",
                    onClick: (row) => handleUpdateStatus(row.email, "denied"),
                  },
                ],
              },
            },
            {
              label: "Edit Multi-Column",
              menu: {
                state: state.status.edit,
                options: [
                  {
                    label: "True",
                    type: "success",
                    onClick: (row) =>
                      handleUpdateMulticolumn(row.email, "true"),
                  },
                  {
                    label: "False",
                    type: "danger",
                    onClick: (row) =>
                      handleUpdateMulticolumn(row.email, "false"),
                  },
                ],
              },
            },
            {
              label: "Upload Data",
              onClick: (row) => setUploadUser(row),
            },
            {
              label: "Delete Data",
              type: "danger",
              onClick: (row) => setDeleteDataEmail(row.email),
            },
            {
              label: "Delete Account",
              type: "danger",
              onClick: (row) => setDeleteAccountEmail(row.email),
            },
          ],
        }}
      ></Table>
    </>
  );
};

export default AdminTable;
