import React, { useState } from "react";
import StartModal from "./StartModal";
import MissingEmail from "./MissingEmail";
import MissingPhoneNumber from "./MissingPhoneNumber";
import MissingVoterId from "./MissingVoterId";

export const MODALS = {
  START: "start",
  VERIFY_EMAIL: "verify_email",
  PHONE_NUMBER: "phone_number",
  VOTER_ID: "voter_id",
};

const MissingUserInfo = ({ userInfo, setUserInfo }) => {
  const [activeModal, setActiveModal] = useState(MODALS.START);
  const [currentUserInfo, setCurrentUserInfo] = useState(userInfo);

  const close = () => setUserInfo(null);

  const updateUserInfoAndSetNextStep = (updates) => {
    const updatedUserInfo = { ...currentUserInfo, ...updates };
    setCurrentUserInfo(updatedUserInfo);

    if (updates.currentStep) {
      setActiveModal(updates.currentStep);
    } else {
      // Determine next step based on missing info
      if (!updatedUserInfo.verified_email) {
        setActiveModal(MODALS.VERIFY_EMAIL);
      } else if (!updatedUserInfo.phone_number) {
        setActiveModal(MODALS.PHONE_NUMBER);
      } else if (!updatedUserInfo.voter_id) {
        setActiveModal(MODALS.VOTER_ID);
      } else {
        setUserInfo(updatedUserInfo);
        close();
      }
    }
  };

  return (
    <div className="fixed inset-0 z-[51] bg-black/30 isolate flex justify-center items-center font-mont">
      <div className="absolute inset-0 -z-10" onClick={close}></div>

      {activeModal === MODALS.START && (
        <StartModal
          userInfo={currentUserInfo}
          close={close}
          setActiveModal={setActiveModal}
        />
      )}

      {activeModal === MODALS.VERIFY_EMAIL && (
        <MissingEmail
          updateUserInfoAndSetNextStep={updateUserInfoAndSetNextStep}
          userInfo={currentUserInfo}
          close={close}
        />
      )}
      {activeModal === MODALS.PHONE_NUMBER && (
        <MissingPhoneNumber
          updateUserInfoAndSetNextStep={updateUserInfoAndSetNextStep}
          userInfo={currentUserInfo}
          close={close}
        />
      )}
      {activeModal === MODALS.VOTER_ID && (
        <MissingVoterId
          updateUserInfoAndSetNextStep={updateUserInfoAndSetNextStep}
          close={close}
        />
      )}
    </div>
  );
};

export default MissingUserInfo;
