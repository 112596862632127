import React from "react";

const Support = () => {
  return (
    <div
      id="support-us"
      className="bg-[#0A293D] font-fira flex justify-center text-center text-peach pt-10 sm:pt-[50px] md:pt-[70px]"
    >
      <div className="justify-center items-center flex flex-col gap-2 sm:gap-4 lg:gap-6 tracking-[0.6px] w-[90%]">
        <h2 className="text-[28px] xs:text-[32px] sm:text-[36px] md:text-[42px] font-black">
          SUPPORT OUR MISSION
        </h2>

        <p className="text-lg sm:text-xl font-black leading-[1.65] max-w-[865px]">
          Join us in shaping Houston's future! Your donation to Fired Up To Vote
          empowers us to connect with voters and ensure their voices are heard
          in local and general elections. Every contribution helps us recruit
          and train dedicated volunteers to achieve our goal of reaching 120,000
          voters in Harris County. Together, we can build a more informed and
          engaged community.
        </p>

        <div className="relative mt-5 sm:mt-7">
          <a
            href="https://givebutter.com/2PSZC5"
            target="_blank"
            className="inline-block text-cherry bg-white font-bold font-mont tracking-[-0.02em] px-[18px] sm:px-[22px] py-[14px] sm:py-[18px]"
          >
            CLICK HERE TO DONATE
          </a>

          <img
            src="/click.png"
            alt="click"
            className="absolute right-0 top-0 translate-x-[130%] w-[70px] sm:w-[111px] hidden xs:block"
          />
        </div>

        <div className="!aspect-[2.5] mt-14 sm:mt-20 w-full max-w-[858px]">
          <img
            src="/hands-vote.png"
            alt="hands holding paper"
            className="w-full h-full object-cover object-top"
            width={858}
            height={585}
          />
        </div>
      </div>
    </div>
  );
};

export default Support;
