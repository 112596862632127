import { createSlice } from "@reduxjs/toolkit";
import { fetchData } from "./dashboardApi";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    data: null,
    status: "loading",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.status = "idle";
      state.data = action.payload.data?.stats["Overall"];
    });
    builder.addCase(fetchData.rejected, (state) => {
      state.status = "idle";
    });
  },
});

export default dashboardSlice.reducer;
