export default function getAppropriateFiltersFormat(columns) {
  const filters = {};

  columns.forEach((col) => {
    if (col.key === "voted" && col.filter.value !== "")
      filters[col.key] = col.filter.value === "Voted" ? "1" : "0";
    else filters[col.key] = col.filter.value.toString().toLowerCase().trim();
  });

  return filters;
}
