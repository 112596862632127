import post from "../utils/post";
import { useNavigate } from "react-router-dom";

const useAxios = () => {
  const navigate = useNavigate();

  return {
    post: async (
      url,
      params = {},
      onSuccess = () => {},
      onError = () => {},
      options = {}
    ) => {
      return await post(url, params, navigate, onSuccess, onError, options);
    },
  };
};

export default useAxios;
