import React from "react";
import Button from "../../components/ui/Button";
import { useDispatch, useSelector } from "react-redux";
import { deleteAccount } from "../../features/adminSlice/adminApi";
import { useNavigate } from "react-router-dom";

const ConfirmDeleteAccount = ({ email, close }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.admin);

  const handleDelete = async () => {
    await dispatch(deleteAccount({ navigate, email }));
    close();
  };

  return (
    <div className="fixed z-[61] inset-0 isolate flex justify-center items-center">
      <div
        className="absolute inset-0 bg-black bg-opacity-30 cursor-pointer -z-10"
        onClick={close}
      ></div>

      <div className="w-[90%] max-w-[500px] bg-white flex flex-col rounded-xl relative p-5">
        <div className="space-y-1">
          <h6 className="text-xl xs:text-2xl text-[#323232] font-semibold font-mont !leading-[1.3]">
            Delete Account
          </h6>
          <p className="text-[#727272]">
            Please confirm that you want to delete this account.
          </p>
        </div>

        <div className="flex gap-2 mt-4 ml-auto">
          <Button
            className="bg-transparent !text-black !border-black"
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            state={status.deleteAccount}
            disabled={status.deleteAccount === "loading"}
            onClick={handleDelete}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteAccount;
