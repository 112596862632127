import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageNotFound from "../../components/PageNotFound";
import Header1 from "../../layout/Header1";
import Loading from "../../components/Loading";
import Main from "./Main";
import Footer from "../../layout/Footer";
import useAxios from "../../hooks/useAxios";

const AccountSetup = () => {
  const [pageExists, setPageExists] = useState(null);

  const { token } = useParams();

  const { post } = useAxios();

  const authenticatePage = async () => {
      await post(
      process.env.REACT_APP_EMAIL_SIGNUP_API_URL,
      { token, purpose: "set_verified_email" },
      (response) => {
        if (response.data.message === "Email verified successfully")
          setPageExists(true);
        else setPageExists(false);
      },
      () => setPageExists(false)
    );
  };

  useEffect(() => {
    authenticatePage();
  }, []);

  if (pageExists === null) {
    return (
      <>
        <Header1 />
        <div className="flex justify-center items-center flex-1">
          <Loading />
        </div>
        <Footer />
      </>
    );
  }

  if (pageExists === true) {
    return (
      <>
        <Header1 />
        <Main />
        <Footer />
      </>
    );
  }

  return <PageNotFound />;
};

export default AccountSetup;
