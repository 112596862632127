import React, { useEffect } from "react";
import Main from "./Main";
import Header2 from "../../layout/Header2";
import Footer from "../../layout/Footer";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { fetchData } from "../../features/dashboardSlice/dashboardApi";
import { useSelector, useDispatch } from "react-redux";

const Dashboard = () => {
  const state = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchData({ navigate }));
  }, []);

  return (
    <>
      <Header2 />
      {state.status === "loading" ? (
        <div className="flex justify-center items-center flex-1">
          <Loading />
        </div>
      ) : (
        <Main />
      )}
      <Footer />
    </>
  );
};

export default Dashboard;
