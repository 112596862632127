import { createAsyncThunk } from "@reduxjs/toolkit";
import post from "../../utils/post";
import getAppropriateFiltersFormat from "../../utils/getAppropriateFiltersFormat";

export const fetchData = createAsyncThunk(
  "team/fetchData",
  async ({ navigate, options }, { getState, rejectWithValue }) => {
    const { perPage, sort, columns } = getState().team;

    const filters = getAppropriateFiltersFormat(columns);

    const page = new URLSearchParams(window.location.search).get("page") ?? 1;

    console.log({ page, per_page: perPage, sort, filters });

    const response = await post(
      process.env.REACT_APP_TEAM_MANAGEMENT_API_URL,
      {
        purpose: "load_table_data",
        page,
        per_page: perPage,
        sort,
        filters,
      },
      navigate,
      null,
      null,
      options,
      false
    );

    if (response?.aborted) return rejectWithValue({ aborted: true });

    return response.data;
  }
);

export const upload = createAsyncThunk(
  "team/upload",
  async ({ navigate, email, data }, { getState }) => {
    const { perPage, sort, columns } = getState().team;

    const filters = getAppropriateFiltersFormat(columns);

    const page = new URLSearchParams(window.location.search).get("page") ?? 1;

    const response = await post(
      process.env.REACT_APP_LOAD_DATA_TABLE_API_URL,
      {
        purpose: "import_data_captain",
        email,
        data,
        page,
        per_page: perPage,
        sort,
        filters,
      },
      navigate
    );


    return response.data;
  }
);






