import { createSlice } from "@reduxjs/toolkit";
import initialState, { DEFAULT_COLUMNS, DEFAULT_SORT } from "./initialState";
import { addVoter, fetchFilteredList } from "./voterIdLookupApi";

const TEMPORARY_DATA = [
  {
    age: 69,
    city: "spring",
    first_name: "Mary",
    full_address: "16510 dover mills dr spring, tx 77379",
    gender: "female",
    house_number: "16510",
    last_name: "Langlinais",
    middle_name: "annette",
    ohps_rank: "TopTier",
    precinct: "514",
    primary_voting_history: "R,R,R,R,R",
    street_name: "Dover Mills Dr",
    voted: "0",
    voter_id: 1000003483,
    zipcode: "77379",
  },
];

const voterIdLookupSlice = createSlice({
  name: "voterIdLookup",
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      const [key, newFilterValue] = action.payload;

      state.columns = state.columns.map((col) => {
        if (col.key !== key) return col;
        return { ...col, filter: { ...col.filter, value: newFilterValue } };
      });
    },

    moveToPage: (state, action) => {
      state.page = action.payload;
    },

    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },

    toggleColumn: (state, action) => {
      const key = action.payload;

      state.columns = state.columns.map((col) => {
        if (col.key === key)
          return {
            ...col,
            isActive: !col.isActive,
            filter: { ...col.filter, value: "" }, // resets filter value
          };
        return col;
      });

      if (state.sort.column === key) state.sort = { column: null, order: null }; // resets sort if any
    },

    resetFilters: (state) => {
      localStorage.removeItem("voter-id-lookup-memory");

      state.columns = DEFAULT_COLUMNS.map((col, i) => ({
        ...col,
        isActive: state.columns[i].isActive,
      }));
      state.sort = DEFAULT_SORT;
      state.filteredList = [];
      state.status.fetchData = "idle";
    },

    setSort: (state, action) => {
      state.sort = action.payload;
    },

    setColumns: (state, action) => {
      state.columns = action.payload;
    },
    toggleTemporaryData: (state) => {
      if (state.filteredList.length === 0) state.filteredList = TEMPORARY_DATA;
      else state.filteredList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilteredList.pending, (state) => {
        state.status.fetchData = "loading";
      })
      .addCase(fetchFilteredList.fulfilled, (state, action) => {
        state.status.fetchData = "success";
        state.filteredList = action.payload?.data || [];
        state.totalPages = action.payload.metadata?.total_pages;
      })
      .addCase(fetchFilteredList.rejected, (state, action) => {
        if (!action.payload.aborted) state.status.fetchData = "idle";
      })

      .addCase(addVoter.pending, (state) => {
        state.status.addVoter = "loading";
      })
      .addCase(addVoter.fulfilled, (state, action) => {
        const voterId = action.payload.voter.voter_id;

        state.status.addVoter = "idle";

        state.filteredList = state.filteredList.map((row) =>
          row.voter_id === voterId ? { ...row, isDisabled: true } : row
        );
      })
      .addCase(addVoter.rejected, (state, action) => {
        state.status.addVoter = "idle";

        const voterId = action.payload?.voter.voter_id;

        if (voterId)
          state.filteredList = state.filteredList.map((row) =>
            row.voter_id === voterId ? { ...row, isDisabled: true } : row
          );
      });
  },
});

export const {
  updateFilters,
  moveToPage,
  setPerPage,
  toggleColumn,
  resetFilters,
  setSort,
  setColumns,
  toggleTemporaryData,
} = voterIdLookupSlice.actions;
export default voterIdLookupSlice.reducer;
