import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { STEPS } from "./Main";
import useAxios from "../../hooks/useAxios";

const MatchUserStep = ({ restart, fetchedVoters, setIsWaiting, setStep }) => {
  const [buttonState, setButtonState] = useState(null);

  const { token } = useParams();
  const { post } = useAxios();

  const handleSelect = async (voter) => {
    setButtonState("loading");

    await post(
      process.env.REACT_APP_EMAIL_SIGNUP_API_URL,
      {
        purpose: "approve_or_wait",
        voter_id: voter.voter_id,
        token,
      },
      (response) => {
        if (response.data.data.status === "waiting") {
          setIsWaiting(true);
          setStep(STEPS.COMPLETE_SIGNUP);
        } else if (
          response.data.data.status === "approved-needs-to-set-password"
        ) {
          setIsWaiting(false);
          setStep(STEPS.COMPLETE_SIGNUP);
        }
      }
    );

    setButtonState(null);
  };

  return (
    <>
      <p className="px-4">
        Please click the select button on the row that matches your registered
        Voter ID.
      </p>

      <table className="px-5 text-start">
        <thead>
          <tr className="border-b border-[#ccc]">
            <th className="text-start pl-6 px-2 pb-3">Voter ID</th>
            <th className="text-start px-2 pb-3">Full Name</th>
            <th className="text-start px-2 pb-3">Full Address</th>
            <th className="text-start pr-4 px-2 pb-3"></th>
          </tr>
        </thead>

        <tbody>
          {fetchedVoters?.map((voter, i) => (
            <tr
              key={i}
              className="border-b border-[#ccc] [&>td]:px-2 [&>td]:py-3"
            >
              <td className="!pl-6">{voter.voter_id}</td>
              <td className="capitalize pl-">
                {voter.first_name} {voter.middle_name} {voter.last_name}
              </td>
              <td className="capitalize">{voter.full_address}</td>
              <td
                onClick={() => handleSelect(voter)}
                className="pr-4 font-bold cursor-pointer text-cherry text-center relative"
              >
                <span
                  className={
                    buttonState === "loading" ? "opacity-0" : "opacity-100"
                  }
                >
                  Submit
                </span>

                {buttonState === "loading" && (
                  <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
                    <Loading size={18} />
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="w-full px-4 flex gap-2">
        <button
          onClick={restart}
          className="flex items-center h-9 rounded-md bg-[#e3e4e9] text-[#45484e] px-[22px] font-semibold"
        >
          Back
        </button>
      </div>
    </>
  );
};

export default MatchUserStep;
