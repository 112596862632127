import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addVoter,
  fetchFilteredList,
} from "../../features/voterIdLookupSlice/voterIdLookupApi";
import {
  moveToPage,
  setPerPage,
  resetFilters,
  toggleColumn,
  toggleTemporaryData,
  updateFilters,
  setSort,
  setColumns,
} from "../../features/voterIdLookupSlice/voterIdLookupSlice";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import useMemory from "../../hooks/useMemory";
import Table from "../../components/table/Table";
import VoterIdLookupSteps from "../../components/steps/VoterIdLookupSteps";
import TableButton from "../../components/table/TableButton";
import {
  addVoterToList,
  updateList,
} from "../../features/myListSlice/myListSlice";

const LookupTable = () => {
  const [isShowingTutorial, setIsShowingTutorial] = useState(false);

  const state = useSelector((state) => state.voterIdLookup);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  // useMemory("voter-id-lookup-memory", state);

  const addedTempData = useRef(false);

  useUpdateEffect(() => {
    dispatch(fetchFilteredList({ navigate, params: { page: 1 } }));
  }, [state.sort]);

  const handleSort = ({ column, order }) => {
    dispatch(setSort({ column, order }));
  };

  const handleChangePage = (toPage) => {
    dispatch(moveToPage(toPage));
    dispatch(fetchFilteredList({ navigate }));
  };

  const handleFilters = (key, value) => dispatch(updateFilters([key, value]));

  const handleToggleColumn = (key) => dispatch(toggleColumn(key));

  const handleResetFilters = () => dispatch(resetFilters());

  const handleChangePerPage = (perPage) => dispatch(setPerPage(perPage));

  const handleSubmit = () => {
    dispatch(fetchFilteredList({ navigate, params: { page: 1 } }));
  };

  const handleStartTutorial = () => {
    setIsShowingTutorial(true);
    if (state.filteredList.length === 0) {
      addedTempData.current = true;
      dispatch(toggleTemporaryData());
    }
  };

  const handleReorder = (columns) => {
    const prevMemory = JSON.parse(
      localStorage.getItem("voter-id-lookup-memory")
    );

    localStorage.setItem(
      "voter-id-lookup-memory",
      JSON.stringify({ ...prevMemory, columns })
    );

    dispatch(setColumns(columns));
  };

  const { listNames } = useSelector((state) => state.myList);

  return (
    <>
      <VoterIdLookupSteps
        enabled={isShowingTutorial}
        onExit={() => {
          setIsShowingTutorial(false);
          if (addedTempData.current) dispatch(toggleTemporaryData());
          addedTempData.current = false;
        }}
      />

      <Table
        state={{ ...state, data: state.filteredList }}
        onSortChange={handleSort}
        onFiltersChange={handleFilters}
        onToggleColumn={handleToggleColumn}
        onResetFilters={handleResetFilters}
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        onSubmit={handleSubmit}
        onStartTutorial={handleStartTutorial}
        onReorder={handleReorder}
        isFocused={!isShowingTutorial}
        emptyPlaceholder={(onResetFilters) => (
          <>
            {state.status.fetchData === "success" && (
              <div className="w-full h-[200px] flex justify-center items-center flex-col gap-4">
                <p className="text-xl font-medium">No records found</p>

                {state.columns.some((c) => c.filter.value) && (
                  <TableButton onClick={onResetFilters} icon="/import.svg">
                    Reset Filters
                  </TableButton>
                )}
              </div>
            )}

            {state.status.fetchData === "idle" && (
              <div className="w-full h-[200px] flex justify-center items-center flex-col gap-4">
                <p className="text-xl font-medium">
                  Search for voters using their first and last names in the
                  boxes above.
                </p>
              </div>
            )}
          </>
        )}
        rowActions={{
          isDropdown: listNames.length > 1,
          triggers: [state.status.addVoter],
          state: state.status.addVoter,
          onClick: async (row) => {
            await dispatch(addVoter({ navigate, voter: row }));
            dispatch(addVoterToList({ voter: row, listName: listNames[0] }));
          },
          label: "Add Voter",
          dropdownTitle: null,
          options: JSON.parse(localStorage.getItem("listNames")).map(
            (listName) => ({
              label: listName,
              onClick: async (row) => {
                await dispatch(
                  addVoter({
                    navigate,
                    voter: row,
                    listName,
                  })
                );
                dispatch(addVoterToList({ voter: row, listName }));
              },
            })
          ),
          className: (isDisabled) =>
            isDisabled ? "text-black" : "text-cherry",
        }}
      />
    </>
  );
};

export default LookupTable;
