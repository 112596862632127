import React from "react";
import Header2 from "../../layout/Header2";
import Footer from "../../layout/Footer";

const TeamManagement = () => {
  return (
    <>
      <Header2 />

      <div className="w-full flex justify-center mt-[110px] xs:mt-[120px] md:mt-[140px] mb-24 font-mont">
        <div
          id="team-management"
          className="w-[90%] max-w-[1400px] items-start flex flex-col font-medium gap-7"
        >
          <h1 className="text-[23px] sm:text-[28px] font-bold text-cherry">
            EMAILS AND TEXT MESSAGES TO SEND TO YOUR VOTERS FOR THE NOVEMBER 5
            ELECTION
          </h1>

          <div className="flex flex-col gap-4">
            <h3 className="text-[21px] text-cherry font-bold">Email 10/21</h3>

            <div>
              <p className="font-bold">Early Voting starts today!</p>
              <p>
                Please <b>click</b>{" "}
                <a
                  href="https://files.harrisvotes.com/harrisvotes/prd/docs/EarlyVotingPDFMaps/November%205%20EVP_(Final%20for%20V03).pdf?sv=2017-04-17&sr=b&si=DNNFileManagerPolicy&sig=EN8WTLHBgkSaoviEoMC1ggeMBoibpok2ctgm3Kd0Kbs%3D"
                  target="_blank"
                  className="underline text-blue-600"
                >
                  HERE
                </a>{" "}
                to see <b>Early Voting locations.</b>
              </p>
            </div>

            <div>
              <p>
                <b>Early Voting locations</b> are <b>open daily</b> through{" "}
                <b>Friday, November 1st, 7am-7pm</b>, except for{" "}
                <b>Sunday, October 27th</b> - polls are <b>open Noon-7pm.</b>
              </p>
              <p>
                <b>Monday, October 28 - Wednesday, October 30</b> polls are open
                late <b>7am - 8pm.</b>
              </p>
              <p>
                <b>Thursday, October 31</b> polls are open late{" "}
                <b>7am - 9pm.</b>
              </p>
              <p>
                <b>Friday, November 1</b> - polls are open <b>7am - 7pm.</b>
              </p>
            </div>

            <p>
              <b>
                Remember to vote <span className="text-cherry">Republican</span>{" "}
                in every single race
              </b>
              –all the way to the bottom of{" "}
              <a
                href="https://www.harrisvotes.com/Voter/Whats-on-my-Ballot"
                target="_blank"
                className="underline text-blue-600"
              >
                your ballot
              </a>
              .
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <h3 className="text-[21px] text-cherry font-bold">
              Email Tuesday 10/22
            </h3>

            <p>
              <b>Let’s Go!! Early Voting has started!</b> Please get to the
              polls this week and avoid the crazy crowds on Election Day.
            </p>

            <p>
              The Dems traditionally outvote Republicans in early voting and
              then pull their cheating stunts on Election Day (remember the
              inoperable machines and no paper in 2022??)
            </p>

            <p>
              The <b>polls</b> are <b>open daily</b> from <b>7am-7pm</b> through{" "}
              <b>Friday, November 1st</b>, except for{" "}
              <b>Sunday, October 27th</b> when they are <b>open Noon-7pm.</b>
            </p>

            <p>
              <b>Early Voting locations</b> can be found{" "}
              <a
                href="https://files.harrisvotes.com/harrisvotes/prd/docs/EarlyVotingPDFMaps/November%205%20EVP_(Final%20for%20V03).pdf?sv=2017-04-17&sr=b&si=DNNFileManagerPolicy&sig=EN8WTLHBgkSaoviEoMC1ggeMBoibpok2ctgm3Kd0Kbs%3D"
                target="_blank"
                className="underline text-blue-600"
              >
                HERE
              </a>
            </p>

            <p>
              <b>
                Remember to Vote "<span className="text-cherry">R</span>" in
                every single race on{" "}
                <a
                  href="https://www.harrisvotes.com/Voter/Whats-on-my-Ballot"
                  target="_blank"
                  className="underline text-blue-600"
                >
                  your ballot
                </a>
              </b>{" "}
              The Harris County races are critical to improving the safety of
              Harris County and enforcing law and order.
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <h3 className="text-[21px] text-cherry font-bold">
              Email Friday or Saturday early 10/26
            </h3>

            <div>
              <p>
                A friendly <b>reminder</b>, Polls are open this weekend:
              </p>
              <p>
                <b>Saturday</b> 7am -7 pm;
              </p>
              <p>
                <b>SUNDAY</b> Noon- 7 pm
              </p>
              <p>
                only <b>y 6 days left</b> of <b>Early Voting!!</b>
              </p>
            </div>

            <div>
              <p>
                Please get to the polls this week by <b>Friday, Nov. 1st.</b>
              </p>
              <p>
                <b>Monday, October 28 - Wednesday, October 30</b> polls are open
                late <b>7am - 8pm.</b>
              </p>
              <p>
                <b>Thursday, October 31</b> polls are open late{" "}
                <b>7am - 9pm.</b>
              </p>
              <p>
                <b>Friday, November 1</b> polls are open <b>7am - 7pm.</b>
              </p>
            </div>

            <div>
              <p>
                <b>Voting locations</b> can be found{" "}
                <a
                  href="https://files.harrisvotes.com/harrisvotes/prd/docs/EarlyVotingPDFMaps/November%205%20EVP_(Final%20for%20V03).pdf?sv=2017-04-17&sr=b&si=DNNFileManagerPolicy&sig=EN8WTLHBgkSaoviEoMC1ggeMBoibpok2ctgm3Kd0Kbs%3D"
                  target="_blank"
                  className="underline text-blue-600"
                >
                  HERE
                </a>
                .
              </p>
              <p>
                <b>Your sample ballot</b> is{" "}
                <a
                  href="https://www.harrisvotes.com/Voter/Whats-on-my-Ballot"
                  target="_blank"
                  className="underline text-blue-600"
                >
                  HERE
                </a>
                .
              </p>
              <p>
                <b>
                  Remember to Vote “<span className="text-cherry">R</span>” in
                  every single race!
                </b>
              </p>
              <p>Your vote matters–we must make it “Too big to rig!”</p>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <h3 className="text-[21px] text-cherry font-bold">
              Email Nov 4th and Text
            </h3>

            <p>
              <b>Tomorrow</b> is your last chance to be part of this historic
              election. Decades from now people will ask who you voted for. What
              will you say?
            </p>

            <div>
              <p>
                <b>Polls</b> are open <b>7am - 7pm</b>. Here's <b>where</b> to{" "}
                <a
                  href="https://files.harrisvotes.com/harrisvotes/prd/docs/EarlyVotingPDFMaps/November%205%20EVP_(Final%20for%20V03).pdf?sv=2017-04-17&sr=b&si=DNNFileManagerPolicy&sig=EN8WTLHBgkSaoviEoMC1ggeMBoibpok2ctgm3Kd0Kbs%3D"
                  target="_blank"
                  className="underline text-blue-600"
                >
                  vote
                </a>
              </p>
              <p>
                Attached is a{" "}
                <a
                  href="https://www.harrisvotes.com/Voter/Whats-on-my-Ballot"
                  target="_blank"
                  className="underline text-blue-600"
                >
                  sample ballot
                </a>{" "}
                with my recommendations in case it’s helpful. Vote for anyone
                you want. Just{" "}
                <span className="text-cherry font-bold">VOTE!</span>
              </p>
            </div>
          </div>

          <h2 className="text-[28px] font-bold text-cherry">Texts</h2>

          <div className="flex flex-col gap-4">
            <h3 className="text-[21px] text-cherry font-bold">
              Text Wed, Oct 23
            </h3>

            <p>
              Only <b>9 days</b> left to beat the crowds and vote early! Don’t
              let last-minute glitches keep you from voting on election day!
              Vote as soon as you can.
            </p>

            <p>
              <b>OPTIONAL Text Oct 24 with 2 Links & Hours</b>
            </p>

            <div>
              <p>
                Only <b>9 days</b> left to beat the crowds and vote early! Don’t
                let last-minute glitches keep you from voting on election day!
                Vote as soon as you can.
              </p>

              <p>
                Find Your{" "}
                <a
                  href="https://www.harrisvotes.com/Vote-Centers"
                  target="_blank"
                  className="underline text-blue-600"
                >
                  Poll
                </a>{" "}
                & View Your{" "}
                <a
                  href="https://www.harrisvotes.com/Voter/Whats-on-my-Ballot"
                  target="_blank"
                  className="underline text-blue-600"
                >
                  Ballot
                </a>
              </p>

              <p>
                <b>Mon. Oct. 28th - Wed. Oct.30th</b> polls open till <b>8pm</b>
              </p>

              <p>
                <b>Thurs. Oct. 31</b> polls open till <b>9pm</b>
              </p>

              <p>
                <b>Fri. Nov. 1</b> polls open till <b>7am - 7pm</b>
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <h3 className="text-[21px] text-cherry font-bold">
              TEXT Fri, Oct. 25th (afternoon)
            </h3>

            <p>
              <b>
                Friendly reminder-polls are open this weekend. Saturday 7 am- 7
                pm and Sunday Noon-7pm
              </b>{" "}
              Find Your{" "}
              <a
                href="https://www.harrisvotes.com/Vote-Centers"
                target="_blank"
                className="underline text-blue-600"
              >
                Poll
              </a>{" "}
              & View Your{" "}
              <a
                href="https://www.harrisvotes.com/Voter/Whats-on-my-Ballot"
                target="_blank"
                className="underline text-blue-600"
              >
                Ballot
              </a>
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <h3 className="text-[21px] text-cherry font-bold">
              Text Mon, Oct 28
            </h3>

            <div>
              <p>
                You’re down to <b>5 days</b> for early voting. Please put early
                voting on your calendar so you don’t forget!
              </p>

              <p>
                Find the most convenient location{" "}
                <a
                  href="https://www.harrisvotes.com/Vote-Centers"
                  target="_blank"
                  className="underline text-blue-600"
                >
                  here
                </a>{" "}
                - Polls are open until 8 pm tonight thru Wednesday night and
                until 9 pm on Halloween.
              </p>
            </div>

            <p>
              <b>OPTIONAL Text Oct 28 with 2 links & Hours</b>
            </p>

            <div>
              <p>
                You’re down to <b>5 days</b> for early voting. Have you put it
                on your calendar so you don’t forget?
              </p>
              <p>
                Find the most convenient <b>location</b>{" "}
                <a
                  href="https://www.harrisvotes.com/Vote-Centers"
                  target="_blank"
                  className="underline text-blue-600"
                >
                  here -
                </a>
              </p>
              <p>
                <b>Mon. Oct. 28th - Wed. 30th</b> polls open <b>7am - 8pm</b>
              </p>
              <p>
                <b>Thurs. Oct. 31st-</b> polls open <b>7am - 9pm</b>
              </p>
              <p>
                <b>Fri. Nov. 1st-</b> polls open <b>7 am - 7 pm</b>
              </p>
            </div>

            <p>
              What's on My <b>Ballot?</b>{" "}
              <a
                href="https://www.harrisvotes.com/Voter/Whats-on-my-Ballot"
                target="_blank"
                className="underline text-blue-600"
              >
                Here
              </a>
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <h3 className="text-[21px] text-cherry font-bold">
              OPTIONAL Text OCT 31
            </h3>

            <div>
              <p>
                Only <b>1 more day</b> for early voting.{" "}
                <a
                  href="https://www.harrisvotes.com/Vote-Centers"
                  target="_blank"
                  className="underline text-blue-600"
                >
                  Polls
                </a>{" "}
                open 7am - 7pm. View Your{" "}
                <a
                  href="https://www.harrisvotes.com/Voter/Whats-on-my-Ballot"
                  target="_blank"
                  className="underline text-blue-600"
                >
                  Ballot
                </a>
              </p>

              <p>
                Vote “R” down the ballot! Find voting locations{" "}
                <a
                  href="https://www.harrisvotes.com/Vote-Centers"
                  target="_blank"
                  className="underline text-blue-600"
                >
                  Here
                </a>
                .
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <h3 className="text-[21px] text-cherry font-bold">
              OPTIONAL Text Nov 3rd
            </h3>

            <p>
              <b>Tuesday</b> is your last chance to be part of this historic
              election. Decades from now people will ask who you voted for. What
              will you say?
            </p>

            <div>
              <p>
                Here's <b>where</b> to{" "}
                <a
                  href="https://files.harrisvotes.com/harrisvotes/prd/docs/EarlyVotingPDFMaps/November%205%20EVP_(Final%20for%20V03).pdf?sv=2017-04-17&sr=b&si=DNNFileManagerPolicy&sig=EN8WTLHBgkSaoviEoMC1ggeMBoibpok2ctgm3Kd0Kbs%3D"
                  target="_blank"
                  className="underline text-blue-600"
                >
                  vote
                </a>{" "}
                -
              </p>

              <p>
                Here is your{" "}
                <a
                  href="https://www.harrisvotes.com/Voter/Whats-on-my-Ballot"
                  target="_blank"
                  className="underline text-blue-600"
                >
                  sample ballot
                </a>
                . Vote for anyone you want. Just{" "}
                <span className="font-bold text-cherry">VOTE!</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default TeamManagement;
