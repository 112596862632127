import React, { useState, useCallback } from "react";
import Button from "../../components/ui/Button";
import { useParams } from "react-router-dom";
import useAxios from "../../hooks/useAxios";

const Report = ({ setStep, previousFormData }) => {
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isNotRegistered, setIsNotRegistered] = useState(false);

  const { token } = useParams();
  const { post } = useAxios();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (isSubmitting) return;
      setIsSubmitting(true);

      const reportData = {
        purpose: "submit_report",
        message,
        token,
        previous_search: previousFormData,
      };

      await post(
        process.env.REACT_APP_EMAIL_SIGNUP_API_URL,
        reportData,
        (response) => {
          if (response.data.success) {
            // toast.success(
            //   "We have received your report. And we will review your account. Please keep an eye on your email for updates."
            // );
            setMessage("");
          } else {
            // toast.error(
            //   response.data.message || "Submission failed. Please try again."
            // );
          }
        }
      );

      setIsSubmitting(false);
    },
    [message, token, previousFormData]
  );

  const handleNotRegistered = useCallback(async () => {
    setIsSubmitting(true);
    const reportData = {
      purpose: "submit_report",
      message: "User is not registered in Harris County",
      token,
      previous_search: previousFormData,
    };

    await post(
      process.env.REACT_APP_EMAIL_SIGNUP_API_URL,
      reportData,
      (response) => {
        if (response.data.success) {
          // toast.success(
          //   "We have received your report. And we will review your account. Please keep an eye on your email for updates."
          // );
          setIsNotRegistered(true);
        } else {
          // toast.error(
          //   response.data.message || "Submission failed. Please try again."
          // );
        }
      }
    );

    setIsSubmitting(false);
  }, [token, previousFormData]);

  return (
    <form onSubmit={handleSubmit} className="w-full flex flex-col gap-[18px]">
      <p className="px-4">Please describe your issue in the box below.</p>

      <div className="flex flex-col gap-3">
        <textarea
          placeholder="Describe the problem here"
          className="mx-4 rounded-md border px-3.5 py-2.5 focus-visible:outline-cherry focus-visible:outline-2 focus-visible:outline-double min-h-[100px] resize-none"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          disabled={isSubmitting || isNotRegistered}
        />
      </div>

      <div className="w-full h-[1px] bg-[#e5e7eb]"></div>

      <div className="w-full px-4 flex justify-between gap-2">
        <button
          type="button"
          onClick={handleNotRegistered}
          disabled={isSubmitting || isNotRegistered}
          className="flex items-center h-9 rounded-md bg-[#e3e4e9] text-[#45484e] px-[22px] font-semibold disabled:opacity-70 disabled:cursor-not-allowed"
        >
          Not in Harris County
        </button>

        <div className="flex gap-2">
          <button
            type="button"
            onClick={() => setStep("input-full-name")}
            className="flex items-center h-9 rounded-md bg-[#e3e4e9] text-[#45484e] px-[22px] font-semibold disabled:opacity-70 disabled:cursor-not-allowed"
          >
            Back
          </button>

          <Button
            state={isSubmitting ? "loading" : ""}
            type="submit"
            disabled={isSubmitting || isNotRegistered}
          >
            Submit
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Report;
