export const DEFAULT_COLUMNS = [
  {
    key: "voter_id",
    text: "Voter ID",
    isActive: true,
    canEdit: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "full_name",
    text: "Full Name",
    isActive: true,
    canEdit: true,
    className: "capitalize",
    filter: { type: "search", value: "" },
  },
  {
    key: "status",
    text: "Status",
    isActive: false,
    canEdit: true,
    className: "capitalize",
    filter: {
      type: "search",
      value: "",
    },
  },
  {
    key: "phone_number",
    text: "Phone Number",
    isActive: true,
    canEdit: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "email",
    text: "Email",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "total_members",
    text: "Network Size",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "role",
    text: "Role",
    isActive: false,
    canEdit: true,
    className: "capitalize",
    filter: {
      type: "search",
      value: "",
    },
  },
  {
    key: "primary_voting_history",
    text: "Primary Voting History",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "signup_timestamp_central",
    text: "Signup Timestamp",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "multi_column",
    text: "Multi Column",
    isActive: false,
    filter: { type: "dropdown", value: "", values: ["true", "false"] },
  },
];

export const DEFAULT_SORT = { column: null, order: null };
export const DEFAULT_PER_PAGE = 100;

const memory = JSON.parse(localStorage.getItem("admin-memory"));

export default {
  filteredList: [],
  totalPages: 0,
  totalUsers: 0,
  page: 1,
  perPage: memory?.perPage || DEFAULT_PER_PAGE,
  sort: memory?.sort || DEFAULT_SORT,
  columns: memory?.columns || DEFAULT_COLUMNS,
  status: {
    fetchData: "idle",
    editStatus: "idle",
    deleteAccount: "idle",
    deleteData: "idle",
    edit: "idle",
    export: "idle",
    upload: "idle",
  },
};
