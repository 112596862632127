import React from "react";

const HowItWorks = () => {
  return (
    <div className="flex justify-center mt-14 mb-10">
      <div className="flex flex-col items-center w-[90%] gap-10 lg:gap-12">
        <h2 className="font-fira text-[28px] xs:text-[32px] sm:text-[36px] md:text-[42px] font-black text-cherry leading-[1.1]">
          HOW IT WORKS
        </h2>

        <div className="flex flex-col lg:flex-row gap-12">
          <div className="w-full flex flex-col items-center text-center gap-6">
            <img
              src="/list.png"
              alt="list"
              className="w-full h-[180px] 2xl:h-[225px] object-contain"
            />
            <h6 className="font-fira font-bold text-xl 2xl:text-2xl !leading-[1.2]">
              Create your personal network list
            </h6>
          </div>

          <div className="w-full flex flex-col items-center text-center gap-6">
            <img
              src="/checklist.png"
              alt="checklist"
              className="w-full h-[180px] 2xl:h-[225px] object-contain"
            />
            <h6 className="font-fira font-bold text-xl 2xl:text-2xl !leading-[1.2]">
              Upload your list to your Fired Up To Vote account
            </h6>
          </div>

          <div className="w-full flex flex-col items-center text-center gap-6">
            <img
              src="/id-card.png"
              alt="id card"
              className="w-full h-[180px] 2xl:h-[225px] object-contain"
            />
            <h6 className="font-fira font-bold text-xl 2xl:text-2xl !leading-[1.2]">
              Contact your personal network before and during early voting, and check your account daily to see who has voted in your network
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;