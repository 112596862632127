export const DEFAULT_COLUMNS = [
  {
    key: "api_name",
    text: "API Name",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "timestamp_epoch",
    text: "Time Stamp",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "request_parameters",
    text: "Request Parameters",
    isActive: true,
    isJson: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "request_time",
    text: "Request Time",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "status_code",
    text: "Status Code",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "error",
    text: "Error (True/False)",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "response_body",
    text: "Response Body",
    isActive: true,
    isJson: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "error_message",
    text: "Error Message",
    isActive: true,
    filter: { type: "search", value: "" },
  },
];

export const DEFAULT_SORT = { column: null, order: null };
export const DEFAULT_PER_PAGE = 100;

const memory = JSON.parse(localStorage.getItem("logs-memory"));

export default {
  data: [],
  totalPages: 0,
  page: 1,
  perPage: memory?.perPage || DEFAULT_PER_PAGE,
  sort: memory?.sort || DEFAULT_SORT,
  columns: memory?.columns || DEFAULT_COLUMNS,
  status: {
    fetchData: "idle",
  },
};
