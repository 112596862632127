export const DEFAULT_COLUMNS = [
  {
    key: "full_name",
    text: "Full Name",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "total_members",
    text: "Network Size",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "percentage_voted",
    text: "Percent Voted",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "non_vuid_rows",
    text: "Missing Voter IDS",
    isActive: true,
    filter: { type: "search", value: "" },
  },
];

export const DEFAULT_SORT = { column: null, order: null };
export const DEFAULT_PER_PAGE = 100;

const memory = JSON.parse(localStorage.getItem("team-memory"));

export default {
  data: [],
  totalPages: 0,
  page: 1,
  perPage: memory?.perPage || DEFAULT_PER_PAGE,
  sort: memory?.sort || DEFAULT_SORT,
  columns: memory?.columns || DEFAULT_COLUMNS,
  status: {
    fetchData: "idle",
    upload: "idle",
  },
};
