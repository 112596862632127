import React, { useEffect, useState } from "react";
import Header2 from "../../layout/Header2";
import Loading from "../../components/Loading";
import PageNotFound from "../../components/PageNotFound";
import AdminTable from "./AdminTable";

const Admin = () => {
  const [pageExists, setPageExists] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("user-role") === "admin") setPageExists(true);
    else setPageExists(false);
  }, []);

  if (pageExists === null) {
    return (
      <>
        <Header2 />
        <div className="flex justify-center items-center flex-1">
          <Loading />
        </div>
      </>
    );
  }

  if (pageExists === true) {
    return (
      <>
        <Header2 />

        <div className="w-full flex justify-center mt-[140px] font-mont mb-10 xs:mb-12 sm:mb-14 lg:mb-20">
          <div
            id="admin"
            className="w-[90%] max-w-[1400px] flex items-start gap-3 relative"
          >
            <AdminTable />
          </div>
        </div>
      </>
    );
  }

  return <PageNotFound />;
};

export default Admin;
