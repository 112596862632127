import React, { useState } from "react";
import Button from "../../components/ui/Button";
import { useDispatch, useSelector } from "react-redux";
import { upload } from "../../features/adminSlice/adminApi";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import {
  Dropdown,
  DropdownMenu,
  DropdownOption,
  DropdownTrigger,
} from "../../components/ui/Dropdown";

const UploadModal = ({ user, close }) => {
  const [selectedList, setSelectedList] = useState(
    user.list_names.length === 1 ? user.list_names[0] : null
  );
  const [file, setFile] = useState(null);

  const { status } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const handleFileSubmit = async (e) => {
    e.preventDefault();

    if (!file) return;

    if (!selectedList) {
      toast.error("Please select a list");
      return;
    }

    let data = [];

    try {
      if (file.name.endsWith(".csv")) {
        await new Promise((resolve, reject) => {
          Papa.parse(file, {
            header: true,
            complete: (results) => {
              data = results.data;
              resolve();
            },
            error: (error) => {
              reject(error);
            },
          });
        });
      } else if (file.name.endsWith(".numbers")) {
        const arrayBuffer = await file.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const rawData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const headers = rawData.shift();
        data = rawData.map((row) => {
          const obj = {};
          headers.forEach((header, index) => {
            obj[header] = row[index] !== undefined ? String(row[index]) : "";
          });
          return obj;
        });
      } else {
        throw new Error("Unsupported file format");
      }

      // Clean up the data
      data = data.map((row) => {
        const cleanRow = {};
        for (let [key, value] of Object.entries(row)) {
          key = key.trim().toLowerCase();
          if (typeof value === "string") {
            value = value.trim();
            if (isNaN(value)) {
              value = value.toLowerCase();
            }
          }
          cleanRow[key] = value;
        }
        return cleanRow;
      });

      // Remove empty rows
      data = data.filter((row) =>
        Object.values(row).some((value) => value && value.trim() !== "")
      );

      console.log("Data to be sent to backend:", data);

      await dispatch(
        upload({ navigate, email: user.email, list_name: selectedList, data })
      );
      toast.success("File uploaded successfully");
      close();
    } catch (error) {
      console.error("Error processing file:", error);
      toast.error(`Failed to process file: ${error.message}`);
    }
  };

  return (
    <div className="fixed z-[51] inset-0 isolate flex justify-center items-center">
      <div
        className="absolute inset-0 bg-black bg-opacity-30 cursor-pointer -z-10"
        onClick={close}
      ></div>

      <div className="w-[90%] max-w-[500px] bg-white flex flex-col rounded-xl relative p-5 gap-3">
        <div className="space-y-1">
          <h6 className="text-xl xs:text-2xl text-[#323232] font-semibold font-mont !leading-[1.3]">
            Upload Data
          </h6>
          <p className="text-[#727272]">
            Upload a file to import data to the email <br />
            This program accepts{" "}
            <span className="text-[#323232] font-semibold">
              CSV and Numbers
            </span>{" "}
            files
          </p>
        </div>

        <form onSubmit={handleFileSubmit}>
          <div>
            <div className="flex items-center justify-center w-full">
              <label
                htmlFor="file-upload"
                className="flex flex-col items-center justify-center w-full h-[115px] border-2 border-[#ccc] border-dashed rounded-lg cursor-pointer bg-[#f3f3f3] hover:bg-[#eaeaea] transition-all"
              >
                <div className="flex flex-col items-center justify-center gap-1.5">
                  <p className="text-[#969696] font-medium">Click to upload</p>

                  <svg
                    className="size-8 text-[#969696]"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                </div>
                <input
                  id="file-upload"
                  type="file"
                  accept=".csv,.numbers"
                  className="hidden"
                  onChange={handleFileUpload}
                />
              </label>
            </div>

            {file && (
              <div className="flex items-center gap-2 mt-3.5">
                <img src="/document.svg" alt="document" className="size-6" />
                <span className="font-fira">{file?.name}</span>
                <button
                  type="button"
                  onClick={() => setFile(null)}
                  className="border border-black p-1.5 hover:bg-[#F9F9FA] transition-colors rounded-md ml-auto"
                >
                  <img src="/cross.svg" alt="cross" className="size-4" />
                </button>
              </div>
            )}

            {file && (
              <Dropdown className="w-full mt-3 mb-1" triggers={[selectedList]}>
                <DropdownTrigger className="flex items-center font-semibold gap-1 md:gap-3 border !h-11 px-2 md:px-3 hover:bg-[#F9F9FA] transition-colors rounded-md text-sm lg:text-[15px] relative">
                  {selectedList
                    ? selectedList
                    : "Select a list to upload voters"}
                  <img
                    src={"/chevron-down.svg"}
                    className="size-6"
                    width={24}
                    height={24}
                  />
                </DropdownTrigger>

                <DropdownMenu className="!min-w-full w-max flex-1 max-h-[125px] overflow-y-auto overscroll-contain">
                  {user.list_names.map((listName, i) => (
                    <DropdownOption
                      key={i}
                      onClick={() => setSelectedList(listName)}
                    >
                      {listName}
                    </DropdownOption>
                  ))}
                </DropdownMenu>
              </Dropdown>
            )}
          </div>

          {status.upload !== "idle" ? (
            <div className="flex flex-col justify-end items-center h-[106px]">
              <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
                <div
                  className="bg-blue-600 h-2.5 rounded-full"
                  style={{ width: `${status.upload}%` }}
                ></div>
              </div>
              <p>Uploading: {status.upload}%</p>
            </div>
          ) : (
            <div className="flex gap-2 mt-4">
              <Button
                type="submit"
                state={status.upload}
                disabled={status.upload === "loading" || !file}
                className="!h-12 text-lg !rounded-lg w-full"
              >
                Submit
              </Button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default UploadModal;
