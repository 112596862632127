import React, { useEffect } from "react";
import Header2 from "../../layout/Header2";
import MyListTable from "./MyListTable";
import Footer from "../../layout/Footer";
import { useDispatch, useSelector } from "react-redux";
import { setColumns } from "../../features/myListSlice/myListSlice";

export const PER_PAGE = 10;

const MyList = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.myList);

  useEffect(() => {
    try {
      const multiColumn = JSON.parse(localStorage.getItem("multi-column"));

      if (multiColumn) {
        const newColumns = selector.columns.map((col) => ({
          ...col,
          isDisabled: false,
        }));

        dispatch(setColumns(newColumns));
      }
    } catch (e) {}
  }, []);

  return (
    <>
      <Header2 />

      <div className="w-full flex justify-center mt-[110px] xs:mt-[120px] md:mt-[140px] font-mont">
        <div
          id="my-list"
          className="w-[90%] max-w-[1400px] flex items-start gap-3"
        >
          <MyListTable />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default MyList;
