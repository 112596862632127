import React, { useEffect, useState } from "react";
import Header1 from "../../layout/Header1";
import Main from "./Main";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import PageNotFound from "../../components/PageNotFound";
import useAxios from "../../hooks/useAxios";

const SetPassword = () => {
  const [pageExists, setPageExists] = useState(null);

  const { token } = useParams();
  const { post } = useAxios();

  const authenticatePage = async () => {
    await post(
      process.env.REACT_APP_RESET_PASSWORD_API_URL,
      { purpose: "verify-reset-token", token },
      () => setPageExists(true),
      () => setPageExists(false)
    );
  };

  useEffect(() => {
    authenticatePage();
  }, []);

  if (pageExists === null) {
    return (
      <>
        <Header1 />
        <div className="flex justify-center items-center flex-1">
          <Loading />
        </div>
      </>
    );
  }

  if (pageExists === true) {
    return (
      <>
        <Header1 />
        <Main />
      </>
    );
  }

  return <PageNotFound />;
};

export default SetPassword;
