import { createAsyncThunk } from "@reduxjs/toolkit";
import post from "../../utils/post";
import getAppropriateFiltersFormat from "../../utils/getAppropriateFiltersFormat";

export const fetchData = createAsyncThunk(
  "logs/fetchData",
  async ({ navigate, options }, { getState, rejectWithValue }) => {
    const { perPage, sort, columns } = getState().logs;

    const filters = getAppropriateFiltersFormat(columns);

    const page = new URLSearchParams(window.location.search).get("page") ?? 1;

    const response = await post(
      process.env.REACT_APP_PULL_LOGS_API_URL,
      {
        purpose: "fetch_logs",
        page,
        per_page: perPage,
        sort,
      },
      navigate,
      null,
      null,
      options,
      false
    );

    if (response?.aborted) return rejectWithValue({ aborted: true });

    return response.data;
  }
);
