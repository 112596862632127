import React from "react";
import { Steps } from "intro.js-react";

const VoterIdLookupSteps = (props) => {
  return (
    <Steps
      steps={[
        {
          element: "#voter-id-lookup #search-btn",
          title: "Search for Voters",
          intro:
            "Click here to search for voters using the information you've entered in the fields above.",
        },
        {
          element: "#voter-id-lookup #toggle-columns",
          title: "Customize Your View",
          intro:
            "Click here to choose which information columns you want to see in your search results.",
        },
        {
          element: "#voter-id-lookup .sort",
          title: "Organize Results",
          intro:
            "Click on any column header to sort your search results based on that information. Click again to reverse the order.",
        },
        {
          element: "#voter-id-lookup .filter",
          title: "Filter Results",
          intro:
            "Type here to further narrow down your search results. This helps you quickly find specific voters.",
        },
        {
          element: "#voter-id-lookup #filter-last_name",
          title: "Last Name Search",
          intro:
            "Including a last name in your search will make it much faster and more accurate.",
        },
        {
          element: "#voter-id-lookup #action",
          title: "Add Voter to List",
          intro:
            "Click 'Add Voter' next to a search result, then select which of your lists you want to add this voter to.",
        },
      ]}
      initialStep={0}
      options={{ doneLabel: "Done" }}
      {...props}
    />
  );
};

export default VoterIdLookupSteps;
