import React, { createContext, useContext, useState } from "react";
import TableHead from "./TableHead";
import Filters from "./Filters";
import TableRow from "./TableRow";
import ToggleColumns from "./ToggleColumns";
import ResetFilters from "./ResetFilters";
import Pagination from "./Pagination";
import Loading from "../Loading";
import TableButton from "./TableButton";
import PageHelper from "./PageHelper";
import DragPreview from "./DragPreview";

const TableProvider = createContext();

export const useTable = () => {
  if (!TableProvider) console.error("TableProvider not provided");

  return useContext(TableProvider);
};

const Table = ({
  state,
  onSortChange,
  onFiltersChange,
  onToggleColumn,
  onResetFilters,
  onChangePage,
  onChangePerPage,
  onSubmit,
  onToggleSelectRow,
  onSelectAllRows,
  onStartTutorial,
  onReorder,
  onEdit,
  isFocused,
  emptyPlaceholder,
  tableActions = [],
  rowActions,
}) => {
  const [resetKey, setResetKey] = useState(0);

  state = {
    ...state,
    onSortChange,
    onFiltersChange,
    onToggleColumn,
    onResetFilters,
    onChangePage,
    onChangePerPage,
    onSubmit,
    onToggleSelectRow,
    onSelectAllRows,
    onStartTutorial,
    onReorder,
    onEdit,
    isFocused,
  };

  return (
    <TableProvider.Provider value={state}>
      <div id="table-wrapper" className="flex flex-col w-full gap-2">
        <div className="flex justify-end gap-2 flex-wrap-reverse">
          {tableActions.map(
            (action, i) =>
              (action.condition === undefined || action.condition) &&
              (action.component ? (
                <action.component key={i} />
              ) : (
                <TableButton
                  key={i}
                  {...action}
                  style={{ order: action.order || "0" }}
                >
                  {action.label}
                </TableButton>
              ))
          )}

          {onStartTutorial && <PageHelper />}
          <ResetFilters setResetKey={setResetKey} />
          <ToggleColumns />
        </div>

        <div className="w-full overflow-x-auto overflow-y-clip pb-[112px] sm:pb-[140px]">
          {onReorder && <DragPreview />}

          <table className="w-full [&_td]:px-2 [&_th]:px-2 border-t border-l border-r">
            <TableHead key={resetKey} />

            <tbody className="isolate relative z-10">
              <Filters key={resetKey} />

              {state.status.fetchData !== "loading" &&
                state.data.map((row, i) => (
                  <TableRow key={i} row={row} index={i} actions={rowActions} />
                ))}
            </tbody>
          </table>
        </div>

        {state.status.fetchData === "loading" && (
          <div className="flex items-center justify-center mt-6 mb-3 -translate-y-[112px] sm:-translate-y-[140px]">
            <Loading />
          </div>
        )}

        <div className="-translate-y-[112px] sm:-translate-y-[140px]">
          {state.data.length === 0 &&
            state.status.fetchData !== "loading" &&
            emptyPlaceholder &&
            emptyPlaceholder(() => {
              onResetFilters();
              setResetKey((prev) => (prev += 1));
            })}
        </div>

        <Pagination />
      </div>
    </TableProvider.Provider>
  );
};

export default Table;
