import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"; // Include useSearchParams
import Loading from "../../components/Loading";
import {
  Dropdown,
  DropdownMenu,
  DropdownOption,
  DropdownTrigger,
} from "../../components/ui/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  addList as addListApi,
  removeList as removeListApi,
  renameList as renameListApi,
} from "../../features/myListSlice/myListApi";
import { toast } from "react-toastify";
import { changeActiveListName } from "../../features/myListSlice/myListSlice";
import Button from "../../components/ui/Button";

const Lists = () => {
  const [isSelectingListName, setIsSelectingListName] = useState(false);
  const [newListName, setNewListName] = useState("");
  const [listNameToDelete, setListNameToDelete] = useState(false);
  const [typedListName, setTypedListName] = useState("");

  const { activeListName, listNames, status } = useSelector(
    (state) => state.myList
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams(); // Initialize search params

  const addList = async (e) => {
    e.preventDefault();

    if (status.addList !== "idle") return;

    if (newListName === "") return;

    await dispatch(addListApi({ navigate, newListName }));
    dispatch(changeActiveListName(newListName));

    setIsSelectingListName(false);
    setNewListName("");
  };

  const removeList = async (e) => {
    e.preventDefault();

    if (listNameToDelete !== typedListName)
      return toast.error("List names do not match!");

    const { payload } = await dispatch(
      removeListApi({ navigate, listName: listNameToDelete })
    );

    if (payload.listName) {
      if (payload.listName === activeListName) {
        dispatch(changeActiveListName(listNames[0]));
      }
    }

    setListNameToDelete(null);
    setTypedListName("");
  };

  const newListInputRef = useRef();
  const deleteListInputRef = useRef();

  useEffect(() => {
    if (listNameToDelete) deleteListInputRef.current?.focus();
    else setTypedListName("");
  }, [listNameToDelete]);

  useEffect(() => {
    if (isSelectingListName) newListInputRef.current?.focus();
  }, [isSelectingListName]);

  const handleListChange = (listName) => {
    dispatch(changeActiveListName(listName));
    setSearchParams({ page: 1 }); // Reset URL to page 1
  };

  return (
    <>
      {listNameToDelete && (
        <div className="fixed z-[61] inset-0 isolate flex justify-center items-center">
          <div
            className="absolute inset-0 bg-black bg-opacity-30 cursor-pointer -z-10"
            onClick={() => setListNameToDelete(null)}
          ></div>

          <div className="w-[90%] max-w-[460px] bg-white flex flex-col gap-5 py-7 sm:py-10 px-4 sm:px-12 rounded-lg relative">
            <button
              className="absolute right-3 top-3"
              onClick={() => setListNameToDelete(null)}
            >
              <img src="/cross.svg" alt="cross" className="size-6" />
            </button>

            <div className="flex flex-col items-center text-center">
              <h6 className="text-[24px] sm:text-[28px] font-semibold">
                Confirm Delete List
              </h6>
              <p className="sm:text-[18px]">
                Enter{" "}
                <span className="font-semibold">'{listNameToDelete}'</span>{" "}
                below to confirm deleting your list
              </p>
            </div>

            <form onSubmit={removeList} className="flex">
              <input
                ref={deleteListInputRef}
                value={typedListName}
                onChange={(e) => setTypedListName(e.target.value)}
                placeholder="Enter list name"
                className="z-10 rounded-md h-12 w-full border px-2 text-[15px] focus-visible:outline-cherry focus-visible:outline-2 focus-visible:outline-double rounded-r-none border-r-0"
              />

              <Button
                state={status.removeList}
                disabled={status.removeList === "loading"}
                className="!h-12 rounded-l-none !px-3 !text-[15px]"
              >
                Confirm
              </Button>
            </form>
          </div>
        </div>
      )}

      <Dropdown triggers={[activeListName, status.addList]} className="order-4">
        <DropdownTrigger className="font-semibold gap-1 md:gap-3 border !h-11 px-2 md:px-3 transition-colors rounded-md text-sm lg:text-[15px] relative">
          <span>{activeListName}</span>
          <img
            src={"/chevron-down.svg"}
            className="size-[1.4em]"
            width={24}
            height={24}
          />
        </DropdownTrigger>

        <DropdownMenu>
          {listNames.map((listName, i) => (
            <DropdownOption
              onClick={() => handleListChange(listName)} // Use the new handler
              key={i}
              className="hover:bg-[#F9F9FA] transition-colors cursor-pointer h-9 flex items-center justify-between font-medium gap-2"
            >
              <span className="text-ellipsis whitespace-nowrap overflow-hidden">
                {listName}
              </span>
              {listNames.length > 1 && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setListNameToDelete(listName);
                  }}
                >
                  <img
                    src="/bin.svg"
                    alt="bin"
                    className="min-w-[18px] sm:min-w-[20px]"
                  />
                </button>
              )}
            </DropdownOption>
          ))}

          {isSelectingListName ? (
            <DropdownOption
              className={`relative transition-colors cursor-pointer h-9 flex items-center justify-between font-medium gap-2 hover:bg-white min-w-[147px]`}
            >
              <></>
              <form onSubmit={addList} className="absolute inset-0 flex">
                <input
                  ref={newListInputRef}
                  placeholder="List Name"
                  value={newListName}
                  onChange={(e) => setNewListName(e.target.value)}
                  className="rounded-l-md h-full w-full border border-r-0 px-2 text-sm outline-none"
                />

                {status.addList !== "idle" && (
                  <div className="border border-l-0 flex justify-center items-center w-10">
                    <Loading size={16} />
                  </div>
                )}

                {status.addList === "idle" && (
                  <button
                    className="border flex justify-center items-center w-10 transition-colors hover:bg-[#F9F9FA]"
                    type="button"
                    onClick={() => {
                      setIsSelectingListName(false);
                      setNewListName("");
                    }}
                  >
                    <img src="/cross.svg" alt="cross" className="w-4" />
                  </button>
                )}

                {status.addList === "idle" && (
                  <button
                    className="border flex justify-center items-center w-10 transition-colors hover:bg-[#F9F9FA]"
                    type="submit"
                  >
                    <img src="/check.svg" alt="check" className="w-5" />
                  </button>
                )}
              </form>
            </DropdownOption>
          ) : (
            <DropdownOption
              className={`relative transition-colors cursor-pointer h-9 flex items-center justify-between font-medium gap-2 hover:bg-[#F9F9FA] min-w-[147px]`}
              onClick={() => setIsSelectingListName(true)}
            >
              <span>Add New List</span>
              <img
                src="/plus.svg"
                alt="add"
                className="w-[18px] sm:w-[22px] translate-y-[0.08em]"
              />
            </DropdownOption>
          )}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default Lists;