import { createAsyncThunk } from "@reduxjs/toolkit";
import getAppropriateFiltersFormat from "../../utils/getAppropriateFiltersFormat";
import post from "../../utils/post";
import { updateUploadStatus } from "./adminSlice";
import { toast } from "react-toastify";

export const fetchFilteredList = createAsyncThunk(
  "admin/fetchFilteredList",
  async ({ navigate, options }, { getState, rejectWithValue }) => {
    const { perPage, sort, columns } = getState().admin;

    const filters = getAppropriateFiltersFormat(columns);

    const page = new URLSearchParams(window.location.search).get("page") ?? 1;

    const response = await post(
      process.env.REACT_APP_ADMIN_API_URL,
      {
        purpose: "load_volunteers",
        page,
        per_page: perPage,
        sort,
        filters,
      },
      navigate,
      null,
      null,
      options,
      false
    );

    if (response?.aborted) return rejectWithValue({ aborted: true });

    return response.data;
  }
);

export const updateStatus = createAsyncThunk(
  "admin/updateStatus",
  async ({ navigate, email, newStatus }, { getState }) => {
    const { page, perPage, sort, columns } = getState().admin;

    const filters = getAppropriateFiltersFormat(columns);

    const response = await post(
      process.env.REACT_APP_ADMIN_API_URL,
      {
        purpose: "change_status",
        email: email,
        new_status: newStatus,
        page,
        per_page: perPage,
        sort,
        filters,
      },
      navigate
    );

    return response.data;
  }
);

export const deleteAccount = createAsyncThunk(
  "admin/deleteAccount",
  async ({ navigate, email }, { getState }) => {
    const { perPage, sort, columns } = getState().admin;

    const filters = getAppropriateFiltersFormat(columns);

    const page = new URLSearchParams(window.location.search).get("page") ?? 1;

    const response = await post(
      process.env.REACT_APP_ADMIN_API_URL,
      {
        purpose: "remove_account_info",
        email,
        page,
        per_page: perPage,
        sort,
        filters,
      },
      navigate
    );

    return response.data;
  }
);

export const deleteData = createAsyncThunk(
  "admin/deleteData",
  async ({ navigate, email }) => {
    const response = await post(
      process.env.REACT_APP_ADMIN_API_URL,
      {
        purpose: "delete_user_voters_data",
        email,
      },
      navigate
    );

    return response.data;
  }
);

export const edit = createAsyncThunk(
  "admin/edit",
  async ({ navigate, email, attribute, value }, { getState }) => {
    const { page, perPage, sort, columns } = getState().admin;

    const filters = getAppropriateFiltersFormat(columns);

    const response = await post(
      process.env.REACT_APP_MODIFY_VALUE_API_URL,
      {
        email,
        attribute,
        value,
        table: "UserAuth",
        page,
        per_page: perPage,
        sort,
        filters,
      },
      navigate
    );

    return response.data;
  }
);

export const exportData = createAsyncThunk(
  "admin/exportData",
  async ({ navigate, start_date, end_date, status }) => {
    const response = await post(
      process.env.REACT_APP_ADMIN_API_URL,
      {
        purpose: "export",
        status,
        start_date,
        end_date,
      },
      navigate
    );

    return response.data;
  }
);

export const upload = createAsyncThunk(
  "admin/upload",
  async ({ navigate, email, list_name, data }, { getState, dispatch }) => {
    const CHUNK_SIZE = 100;

    const { perPage, sort, columns } = getState().team;

    const filters = getAppropriateFiltersFormat(columns);

    const page = new URLSearchParams(window.location.search).get("page") ?? 1;

    const totalChunks = Math.ceil(data.length / CHUNK_SIZE);
    let processedChunks = 0;
    let totalRowsUploaded = 0;

    for (let i = 0; i < totalChunks; i++) {
      const chunk = data.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);

      const response = await post(
        process.env.REACT_APP_LOAD_DATA_TABLE_API_URL,
        {
          purpose: "import_data_captain",
          email,
          data: chunk,
          page,
          per_page: perPage,
          sort,
          filters,
          list_name,
        },
        navigate,
        null,
        null,
        {},
        false
      );

      processedChunks++;
      totalRowsUploaded += chunk.length;
      const progress = Math.round((processedChunks / totalChunks) * 100);

      dispatch(updateUploadStatus({ percent: progress }));

      if (i === totalChunks - 1) return response.data;
    }
  }
);
