import React from "react";
import Button from "./ui/Button";

const ConfirmDeleteData = ({ close, state, onDelete }) => {
  const handleDelete = async () => {
    await onDelete();
    close();
  };

  return (
    <div className="fixed z-[61] inset-0 isolate flex justify-center items-center">
      <div
        className="absolute inset-0 bg-black bg-opacity-30 cursor-pointer -z-10"
        onClick={close}
      ></div>

      <div className="w-[90%] max-w-[500px] bg-white flex flex-col rounded-xl relative p-5">
        <div className="space-y-1">
          <h6 className="text-xl xs:text-2xl text-[#323232] font-semibold font-mont !leading-[1.3]">
            Delete Account Data
          </h6>
          <p className="text-[#727272]">
            Please confirm that you want to delete all the data from this
            account.
          </p>
        </div>

        <div className="flex gap-2 mt-4 ml-auto">
          <Button
            className="bg-transparent !text-black !border-black"
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            state={state}
            disabled={state === "loading"}
            onClick={handleDelete}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteData;
