import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { addOneVoter } from "../../features/myListSlice/myListApi";
import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  DropdownMenu,
  DropdownOption,
  DropdownTrigger,
} from "../../components/ui/Dropdown";
import { addVoterToList } from "../../features/myListSlice/myListSlice";
import { toast } from "react-toastify";

const ManualSearchResults = ({ close, back }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state.myList);

  const [selectedList, setSelectedList] = useState(
    state.listNames.length === 1 ? state.listNames[0] : null
  );

  const onAddVoter = async (voter) => {
    await dispatch(
      addOneVoter({
        navigate,
        params: { voter_id: String(voter.voter_id), list_name: selectedList },
      })
    );

    dispatch(addVoterToList({ voter, listName: selectedList }));

    close();
  };

  return (
    <div className="w-[90%] overflow-hidden max-h-[90vh] max-w-[780px] bg-white flex flex-col rounded-xl relative">
      <div className="bg-[#FAFAFA] rounded-t-xl px-4 xs:px-5 pt-5 space-y-2.5 pb-1">
        <div className="flex justify-between items-start gap-6">
          <div className="flex gap-4">
            {back && (
              <button onClick={back} className="w-4 flex justify-center">
                <img
                  src="/chevron-down.svg"
                  alt=""
                  className="rotate-90 min-w-[200%]"
                />
              </button>
            )}

            <div className="flex flex-col gap-0.5">
              <h6 className="text-xl xs:text-2xl text-[#323232] font-semibold font-mont !leading-[1.3]">
                Results
              </h6>

              <p className="text-[#727272]">
                Select the voter you want to add to your list
              </p>

              <Dropdown className="w-max mt-3 mb-1" triggers={[selectedList]}>
                <DropdownTrigger className="flex items-center font-semibold gap-1 md:gap-3 border !h-11 px-2 md:px-3 hover:bg-[#F9F9FA] transition-colors rounded-md text-sm lg:text-[15px] relative">
                  {selectedList ? selectedList : "Select List"}
                  <img
                    src={"/chevron-down.svg"}
                    className="size-6"
                    width={24}
                    height={24}
                  />
                </DropdownTrigger>

                <DropdownMenu className="!min-w-full w-max flex-1">
                  {state.listNames.map((listName, i) => (
                    <DropdownOption
                      key={i}
                      onClick={() => setSelectedList(listName)}
                    >
                      {listName}
                    </DropdownOption>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>

          <button
            onClick={close}
            className="bg-white border-black border size-8 min-w-8 rounded-lg flex justify-center items-center"
          >
            <img src="/cross.svg" className="size-4" />
          </button>
        </div>
      </div>

      <div className="w-full h-full overflow-y-auto red-scrollbar overscroll-contain">
        <table className="w-full">
          <thead className="bg-[#FAFAFA] border-b text-[#969696] sticky top-0 z-10">
            <tr>
              <th className="py-4 text-sm sm:text-base px-3.5 sm:px-5 font-semibold text-start whitespace-nowrap">
                <div className="flex gap-1 sm:gap-2.5 items-center">
                  <img src="/id.svg" />
                  <span>Voter ID</span>
                </div>
              </th>
              <th className="py-4 text-sm sm:text-base px-3.5 sm:px-5 font-semibold text-start whitespace-nowrap">
                <div className="flex gap-1 sm:gap-2.5 items-center">
                  <img src="/school-report-card.svg" />
                  <span>Full Name</span>
                </div>
              </th>
              <th className="py-4 text-sm sm:text-base px-3.5 sm:px-5 font-semibold text-start whitespace-nowrap">
                <div className="flex gap-1 sm:gap-2.5 items-center">
                  <img src="/address-book.svg" />
                  <span>Home Address</span>
                </div>
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody className="text-[#727272]">
            {state.searchedVoters.map((voter, index) => (
              <tr key={index} className="border-b">
                <td className="py-2.5 text-sm sm:text-base px-3.5 sm:px-5 font-medium border-r">
                  {voter.voter_id}
                </td>
                <td className="py-2.5 text-sm sm:text-base px-3.5 sm:px-5 font-medium border-r capitalize">
                  {voter.first_name} {voter.middle_name} {voter.last_name}
                </td>
                <td className="py-2.5 text-sm sm:text-base px-3.5 sm:px-5 font-medium border-r capitalize">
                  {voter.full_address}
                </td>
                <td className="px-3.5 sm:px-5 text-sm sm:text-base font-semibold text-cherry text-center">
                  <button
                    disabled={state.status.addOneVoter === "loading"}
                    onClick={() => {
                      if (!selectedList) toast.error("Please select a list");
                      else onAddVoter(voter);
                    }}
                    className="relative w-full disabled:opacity-70"
                  >
                    Select
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManualSearchResults;
