import React from "react";
import Header1 from "../../layout/Header1";
import data from "./data.json";
import Footer from "../../layout/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Header1 />

      <div className="flex justify-center">
        <div className="w-[90%] max-w-[1400px] mt-[120px] sm:mt-[130px] space-y-5 mb-20 sm:mb-[100px]">
          <div className="space-y-1">
            <h6 className="text-xl font-fira">{data.date}</h6>
            <h6 className="text-3xl font-medium font-fira">Fired Up To Vote</h6>
          </div>

          <h6 className="text-2xl font-medium font-fira">Privacy Policy</h6>

          <div className="space-y-1">
            <h6 className="text-xl font-medium font-fira">Background</h6>
            <p className="font-fira text-lg">{data.background}</p>
          </div>

          {data.sections.map((section, i) => (
            <div key={i} className="space-y-1">
              <h6 className="text-xl font-medium font-fira">{section.title}</h6>
              {section.content.map((item, j) => (
                <p key={j} className="font-fira text-lg">
                  {item}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
