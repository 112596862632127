import React from "react";
import { useTable } from "./Table";
import TableButton from "./TableButton";

const ResetFilters = ({ setResetKey }) => {
  const { onResetFilters } = useTable();

  return (
    <TableButton
      onClick={() => {
        onResetFilters();
        setResetKey((prev) => (prev += 1));
      }}
      className="order-2"
    >
      Reset Filters
    </TableButton>
  );
};

export default ResetFilters;
