import React, { useState } from "react";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/ui/Button";
import { useDispatch, useSelector } from "react-redux";
import { upload } from "../../features/myListSlice/myListApi";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import downloadAsCsv from "../../utils/downloadAsCsv";
import * as XLSX from "xlsx";
import {
  Dropdown,
  DropdownMenu,
  DropdownOption,
  DropdownTrigger,
} from "../../components/ui/Dropdown";

const TEMPLATE_CSV = [["First Name", "Last Name", "Voter ID"]];

const Upload = ({ close }) => {
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.myList);

  const [selectedList, setSelectedList] = useState(
    state.listNames.length === 1 ? state.listNames[0] : null
  );

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const handleFileSubmit = async (e) => {
    e.preventDefault();

    if (!file) return;

    if (!selectedList) {
      toast.error("Please select a list");
      return;
    }

    let data = [];

    if (file.name.endsWith(".csv")) {
      await new Promise((resolve, reject) => {
        Papa.parse(file, {
          header: true,
          complete: (results) => {
            data = results.data;
            resolve();
          },
          error: (error) => {
            console.error("Error parsing CSV file:", error);
            toast.error(
              "Failed to parse CSV file. Please check the file format and try again."
            );
            reject(error);
          },
        });
      });
    } else if (file.name.endsWith(".numbers")) {
      try {
        const arrayBuffer = await file.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const rawData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Remove header row
        const headers = rawData.shift();

        // Process data to match CSV format
        data = rawData.map((row) => {
          const obj = {};
          headers.forEach((header, index) => {
            obj[header] = row[index] !== undefined ? String(row[index]) : "";
          });
          return obj;
        });
      } catch (error) {
        console.error("Error parsing Numbers file:", error);
        toast.error(
          "Failed to parse Numbers file. Please check the file format and try again."
        );
        return;
      }
    }

    // Clean up the data
    data = data.map((row) => {
      const cleanRow = {};
      for (let [key, value] of Object.entries(row)) {
        // Trim whitespace from keys and convert to lowercase
        key = key.trim().toLowerCase();

        // Trim whitespace from all values
        if (typeof value === "string") {
          value = value.trim();
          // Convert to lowercase if it's not a number
          if (isNaN(value)) {
            value = value.toLowerCase();
          }
        }

        cleanRow[key] = value;
      }
      return cleanRow;
    });

    // Remove empty rows
    data = data.filter((row) =>
      Object.values(row).some((value) => value && value.trim() !== "")
    );

    console.log("Data to be sent to backend:", data);
    dispatch(upload({ navigate, data, listName: selectedList }));
  };

  const resetForm = () => {
    setFile(null);
  };

  useUpdateEffect(() => {
    if (state.status.upload === 100) { // Ensure it only closes when upload is 100%
      close();
      resetForm();
    }
  }, [state.status.upload]);

  return (
    <form
      onSubmit={handleFileSubmit}
      className="p-4 xs:p-5 flex flex-col gap-4"
    >
      <div className="space-y-0.5">
        <h6 className="text-[#323232] font-semibold text-lg">Instructions</h6>
        <p className="text-[#969696] leading-[1.35] text-lg">
          Upload a file containing names to search for matching records in the
          database. <br />
          This program accepts{" "}
          <span className="text-[#323232] font-semibold">
            CSV and Numbers
          </span>{" "}
          files
        </p>
      </div>

      <div>
        <div className="flex items-center justify-center w-full">
          <label
            htmlFor="file-upload"
            className="flex flex-col items-center justify-center w-full h-[115px] border-2 border-[#ccc] border-dashed rounded-lg cursor-pointer bg-[#f3f3f3] hover:bg-[#eaeaea] transition-all"
          >
            <div className="flex flex-col items-center justify-center gap-1.5">
              <p className="text-[#969696] font-medium">Click to upload</p>

              <svg
                className="size-8 text-[#969696]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
            </div>
            <input
              id="file-upload"
              type="file"
              accept=".csv,.numbers"
              className="hidden"
              onChange={handleFileUpload}
            />
          </label>
        </div>

        {file && (
          <div className="flex items-center gap-2 mt-3.5">
            <img src="/document.svg" alt="document" className="size-6" />
            <span className="font-fira">{file?.name}</span>
            <button
              type="button"
              onClick={() => setFile(null)}
              className="border border-black p-1.5 hover:bg-[#F9F9FA] transition-colors rounded-md ml-auto"
            >
              <img src="/cross.svg" alt="cross" className="size-4" />
            </button>
          </div>
        )}

        {file && (
          <Dropdown className="w-full mt-3 mb-1" triggers={[selectedList]}>
            <DropdownTrigger className="flex items-center font-semibold gap-1 md:gap-3 border !h-11 px-2 md:px-3 hover:bg-[#F9F9FA] transition-colors rounded-md text-sm lg:text-[15px] relative">
              {selectedList ? selectedList : "Select a list to upload voters"}
              <img
                src={"/chevron-down.svg"}
                className="size-6"
                width={24}
                height={24}
              />
            </DropdownTrigger>

            <DropdownMenu className="!min-w-full w-max flex-1 h-[125px] overflow-y-auto overscroll-contain">
              {state.listNames.map((listName, i) => (
                <DropdownOption
                  key={i}
                  onClick={() => setSelectedList(listName)}
                >
                  {listName}
                </DropdownOption>
              ))}
            </DropdownMenu>
          </Dropdown>
        )}
      </div>

      {state.status.upload !== "idle" ? (
        <div className="flex flex-col justify-end items-center h-[106px]">
          <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
            <div
              className="bg-blue-600 h-2.5 rounded-full"
              style={{ width: `${state.status.upload}%` }}
            ></div>
          </div>
          <p>Uploading: {state.status.upload}%</p>
        </div>
      ) : (
        <div className="flex flex-col gap-2.5">
          <button
            type="button"
            onClick={() => downloadAsCsv(TEMPLATE_CSV)}
            className="h-[48px] font-semibold text-[#323232] border rounded-lg flex items-center justify-center gap-2"
          >
            <img src="/import.svg" alt="add" className="h-6" />
            <span>Download Template CSV</span>
          </button>

          <Button
            type="submit"
            disabled={!file}
            className="!h-12 text-lg !rounded-lg"
          >
            Submit
          </Button>
        </div>
      )}
    </form>
  );
};

export default Upload;
