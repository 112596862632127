import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "../../components/ui/Button";
import Input from "../../components/ui/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  addOneVoter,
  searchVoters,
} from "../../features/myListSlice/myListApi";
import { useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownMenu,
  DropdownOption,
  DropdownTrigger,
} from "../../components/ui/Dropdown";
import { addVoterToList } from "../../features/myListSlice/myListSlice";

const ManualSearch = ({ close, setModalType }) => {
  const [isChoosingList, setIsChoosingList] = useState(false);
  const [selectedList, setSelectedList] = useState(null);

  const [showAddUserButton, setShowAddUserButton] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
  });

  const state = useSelector((state) => state.myList);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const clearForm = () => {
    setFormData({
      first_name: "",
      middle_name: "",
      last_name: "",
    });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.first_name === "" || formData.last_name === "") return;

    const { payload } = await dispatch(searchVoters({ navigate, formData }));

    if (payload.data?.length > 0) {
      setModalType("results");
      clearForm();
    } else setShowAddUserButton(true);
  };

  const handleAddUser = async (listName) => {
    const lowerFormData = Object.keys(formData).reduce((acc, curr) => {
      return { [curr]: formData[curr].toLowerCase(), ...acc };
    }, {});

    const { payload } = await dispatch(
      addOneVoter({
        navigate,
        params: {
          voter_id: "missing",
          ...lowerFormData,
          list_name: listName || selectedList,
        },
      })
    );

    if (payload.data) {
      dispatch(
        addVoterToList({
          voter: payload.data,
          listName: listName || selectedList,
        })
      );
    }

    clearForm();
    setShowAddUserButton(false);
    close();
  };

  return (
    <>
      {isChoosingList && (
        <>
          <div
            onClick={() => setIsChoosingList(false)}
            className="fixed inset-0 bg-black/30 z-10"
          />

          <div className="px-4 py-4 max-w-[300px] bg-white fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-lg flex flex-col gap-4 z-20">
            <p>Please choose a list in which you want to add the voter</p>

            <Dropdown className="w-full" triggers={[selectedList]}>
              <DropdownTrigger className="flex items-center font-semibold gap-1 md:gap-3 border !h-11 px-2 md:px-3 hover:bg-[#F9F9FA] transition-colors rounded-md text-sm lg:text-[15px] relative">
                {selectedList ? selectedList : "Select List"}
                <img
                  src={"/chevron-down.svg"}
                  className="size-6"
                  width={24}
                  height={24}
                />
              </DropdownTrigger>

              <DropdownMenu className="!min-w-full w-max flex-1 h-[125px] overflow-y-auto overscroll-contain">
                {state.listNames.map((listName, i) => (
                  <DropdownOption
                    key={i}
                    onClick={() => setSelectedList(listName)}
                  >
                    {listName}
                  </DropdownOption>
                ))}
              </DropdownMenu>
            </Dropdown>

            <div className="flex gap-2">
              <Button
                type="button"
                className="w-full bg-transparent !text-black !border-black h-[44px]"
                onClick={() => setIsChoosingList(false)}
              >
                Cancel
              </Button>

              <Button
                type="button"
                disabled={!selectedList}
                onClick={() => {
                  handleAddUser();
                  setIsChoosingList(false);
                }}
                className="w-full h-[44px] !rounded-lg"
              >
                Submit
              </Button>
            </div>
          </div>
        </>
      )}

      <form onSubmit={handleSubmit} className="p-4 xs:p-5 flex flex-col gap-4">
        <div className="space-y-0.5">
          <h6 className="text-[#323232] font-semibold text-lg">Instructions</h6>
          <p className="text-[#969696] leading-[1.35] text-lg">
            Enter the first, middle, and last name manually to search for
            matching records in the database.
          </p>
        </div>

        <div className="flex flex-col gap-2.5 text-lg">
          <Input
            type="text"
            name="first_name"
            placeholder="First Name"
            value={formData.first_name}
            onChange={handleChange}
          />

          <Input
            type="text"
            name="middle_name"
            placeholder="Middle Name"
            value={formData.middle_name}
            onChange={handleChange}
          />

          <Input
            type="text"
            name="last_name"
            placeholder="Last Name"
            value={formData.last_name}
            onChange={handleChange}
          />
        </div>

        <div className="flex gap-2 justify-end">
          {showAddUserButton && (
            <Button
              type="button"
              state={state.status.addOneVoter}
              disabled={
                formData.first_name === "" ||
                formData.last_name === "" ||
                state.status.search === "loading" ||
                state.status.addOneVoter === "loading"
              }
              onClick={() => {
                if (state.listNames.length === 1)
                  handleAddUser(state.listNames[0]);
                else setIsChoosingList(true);
              }}
              className="w-full h-[48px] text-lg !rounded-lg bg-blue-500 hover:bg-blue-600 flex-grow"
            >
              Add Without VUID
            </Button>
          )}

          <Button
            type="submit"
            state={state.status.search}
            disabled={
              formData.first_name === "" ||
              formData.last_name === "" ||
              state.status.search === "loading" ||
              state.status.addOneVoter === "loading"
            }
            className={`w-full h-[48px] text-lg !rounded-lg ${
              showAddUserButton ? "w-1/2" : "w-full"
            }`}
          >
            Search
          </Button>
        </div>
      </form>
    </>
  );
};

export default ManualSearch;
